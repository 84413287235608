// @flow

const messages = {
  YES: 'Ja',
  NO: 'Nee',
  REQUIRED: 'Verplicht',
  SUBMIT_CHANGES: 'Dien aanpassingen in',
  PROPERTY_FIELD_TYPE_link_TEXT_PLACEHOLDER: 'Tekst om weer te geven',
  PROPERTY_FIELD_TYPE_link_LINK_PLACEHOLDER: 'Plak of typ de link hier',
  VALIDATIONS__INVALID_PHONE_NUMBER: 'Ongeldig telefoonnummer',
  EMPTY_VALUE: 'Leeg'
};

export default messages;
