// @flow

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { head } from 'lodash';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { push, replace } from '@hypercharge/hyper-react-base/lib/router';
import { info } from '@hypercharge/hyper-react-base/lib/notifications';
import {
  createValidator,
  required,
  minLength,
  maxLength,
  integer,
  email
} from '@hypercharge/hyper-react-base/lib/form/validation';
import { AUTH_PATH, ACCOUNTS_PATH } from '../../constants.js';
import { getDisplayTenant } from '../../selectors.js';
import { requestAuthCodeByEmail, retrieveFactorAccounts, logIn } from '../../actions.js';
import { handleFailure } from '../common/utils.js';
import CodeForm from '../common/CodeForm.js';

import type { LocationShape } from 'react-router-dom';
import type { DispatchT } from '@hypercharge/hyper-react-base/lib/types';
import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { MapOfFieldValidationsT } from '@hypercharge/hyper-react-base/lib/form/validation';
import type { GlobalStateT } from '../../../common/reducers/reducers.js';
import type { FactorAccountT, DisplayTenant } from '../../types.js';

type OwnPropsT = {
  // injected by react-router
  location: LocationShape,
  // injected by redux-form
  submitting: boolean,
  handleSubmit: (data: { [field: string]: string }) => Promise<any>
};

type ConnectedStatePropsT = {
  displayTenant: DisplayTenant
};

type ConnectedDispatchPropsT = {
  dispatchRequestCode: (tenantId: string, newCode?: boolean) => void,
  redirectOnWrongInit: () => void
};

type ConnectedMergePropsT = ConnectedStatePropsT &
  ConnectedDispatchPropsT & {
    requestCode: (newCode?: boolean) => void
  };

type PropsT = OwnPropsT & ConnectedMergePropsT & TranslatableT;

type CodeVerificationFormT = {
  code: string
};

const form = 'get-started-code';

const formFields: MapOfFieldValidationsT = {
  code: [required, integer, minLength(6), maxLength(6)]
};

const validate = createValidator(formFields);

const emailValidator = createValidator({ email: [required, email] });

const onSubmit = (
  { code }: CodeVerificationFormT,
  dispatch: DispatchT,
  {
    displayTenant,
    location: {
      search,
      state: { email }
    },
    translate
  }: PropsT
) => {
  const handleSuccess = (factorAccounts: FactorAccountT[]) => {
    if (factorAccounts.length === 0) {
      // TODO: handle this case -> they don't have an account
      return;
    } else if (factorAccounts.length === 1) {
      const factorAccount: FactorAccountT = head(factorAccounts);
      return dispatch(logIn(displayTenant.id, factorAccount.entityId, email, code)).catch(
        handleFailure(dispatch, translate)
      );
    } else {
      return dispatch(
        push({
          pathname: `${AUTH_PATH}${ACCOUNTS_PATH}`,
          search,
          state: { factorAccounts, email, code }
        })
      );
    }
  };

  return dispatch(retrieveFactorAccounts(displayTenant.id, email, code)).then(
    handleSuccess,
    handleFailure(dispatch, translate)
  );
};

class GetStartedCodeFormContainer extends Component<PropsT> {
  componentDidMount() {
    if (this.isWronglyInitialized()) {
      this.props.redirectOnWrongInit();
    } else {
      this.props.requestCode();
    }
  }

  isWronglyInitialized = () => {
    const { email } = this.props.location.state;
    return emailValidator({ email }).email;
  };

  render() {
    const {
      displayTenant,
      requestCode,
      submitting,
      handleSubmit,
      location: {
        state: { email }
      }
    } = this.props;
    return (
      <CodeForm
        displayTenant={displayTenant}
        email={email}
        submitting={submitting}
        handleSubmit={handleSubmit}
        sendNewCode={requestCode}
        showEditLink
      />
    );
  }
}

function mapStateToProps(s: GlobalStateT): ConnectedStatePropsT {
  return {
    displayTenant: getDisplayTenant(s)
  };
}

function mapDispatchToProps(
  dispatch: DispatchT,
  {
    location: {
      state: { email }
    },
    translate,
    language
  }: PropsT
): ConnectedDispatchPropsT {
  return {
    dispatchRequestCode: (tenantId: string, newCode?: boolean) => {
      dispatch(requestAuthCodeByEmail(tenantId, email, language)).then(() => {
        if (newCode) {
          dispatch(
            info({
              title: translate('COMMON__INFO'),
              message: translate('AUTH_A_NEW_CODE_WAS_SENT')
            })
          );
        }
      }, handleFailure(dispatch, translate, 'AUTH_REQUEST_CODE_ERROR_MESSAGE'));
    },
    redirectOnWrongInit: () => {
      dispatch(replace(AUTH_PATH));
    }
  };
}

function mergeProps(
  stateProps: ConnectedStatePropsT,
  dispatchProps: ConnectedDispatchPropsT,
  ownProps: OwnPropsT
): ConnectedMergePropsT {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    requestCode: newCode => {
      dispatchProps.dispatchRequestCode(stateProps.displayTenant.id, newCode);
    }
  };
}

export default compose(
  injectI18n,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  reduxForm({
    form,
    onSubmit,
    validate
  })
)(GetStartedCodeFormContainer);
