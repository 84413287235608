// @flow

import { createReducer } from '@hypercharge/hyper-react-base/lib/utils/reducerUtils.js';
import type { DisplayGdprConfig } from '@hypercharge/portal-utils';
import type { DisplayDataList } from '../cms';
import type {
  FetchDisplayGdprConfigT,
  FetchDisplayGdprConfigSuccessT,
  FetchDisplayGdprConfigFailT,
  FetchMeT,
  FetchMeSuccessT,
  FetchMeFailT
} from './types.js';

type FetchStatusT = {
  pending: boolean,
  failed: boolean
};

export type StateT = {
  displayGdprConfig: ?DisplayGdprConfig,
  displayGdprConfigStatus: FetchStatusT,
  me: ?DisplayDataList,
  meStatus: FetchStatusT
};

const initialFetchStatus: FetchStatusT = { pending: false, failed: false };

const initialState: StateT = {
  displayGdprConfig: null,
  displayGdprConfigStatus: initialFetchStatus,
  me: null,
  meStatus: initialFetchStatus
};

const meReducer = createReducer(initialState, {
  ME__FETCH_CONFIG: (s: StateT, a: FetchDisplayGdprConfigT) => {
    s.displayGdprConfigStatus.pending = true;
    s.displayGdprConfigStatus.failed = false;
  },
  ME__FETCH_CONFIG_SUCCESS: (s: StateT, a: FetchDisplayGdprConfigSuccessT) => {
    s.displayGdprConfig = a.payload;
    s.displayGdprConfigStatus.pending = false;
  },
  ME__FETCH_CONFIG_FAIL: (s: StateT, a: FetchDisplayGdprConfigFailT) => {
    s.displayGdprConfigStatus.pending = false;
    s.displayGdprConfigStatus.failed = true;
  },
  ME__FETCH_ME: (s: StateT, a: FetchMeT) => {
    s.meStatus.pending = true;
    s.meStatus.failed = false;
    s.me = null;
  },
  ME__FETCH_ME_SUCCESS: (s: StateT, a: FetchMeSuccessT) => {
    s.me = a.payload;
    s.meStatus.pending = false;
  },
  ME__FETCH_ME_FAIL: (s: StateT, a: FetchMeFailT) => {
    s.meStatus.pending = false;
    s.meStatus.failed = true;
  }
});

export default meReducer;
