// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { Helmet } from 'react-helmet';
import { padStart } from 'lodash';
import { Link } from 'react-router-dom';
import IoAndroidCreate from 'react-icons/lib/io/android-create';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { FrameContainer, Title, Subtitle } from '../../../common/components/frame';
import Logo from '../../../common/components/logo';
import { AUTH_PATH, GET_STARTED_BY_EMAIL_PATH } from '../../constants.js';
import { Email, SubmitButton, CenteredText } from './components.js';

import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { DisplayTenant } from '../../types.js';

type PropsT = {
  displayTenant: DisplayTenant,
  email: string,
  sendNewCode: (newCode?: boolean) => void,
  showEditLink: boolean,
  // injected by redux-form
  submitting: boolean,
  handleSubmit: (data: { [field: string]: string }) => ?Promise<any>
} & TranslatableT;

type StateT = {
  intervalId: ?IntervalID,
  secondsUntilEnableResendCode: number,
  newCodeWasSent: boolean
};

type SendNewCodePropsT = {
  disabled: boolean,
  theme: Object
};

const SendNewCode = styled.div`
  color: ${(props: SendNewCodePropsT) =>
    props.disabled ? 'rgba(165,165,165,0.37)' : props.theme.mainColor};
  cursor: ${(props: SendNewCodePropsT) => (props.disabled ? 'default' : 'pointer')};
`;
const EditLink = styled(Link)`
  transition: color 200ms ease;

  &:hover {
    color: ${props => props.theme.mainColorVibrant};
  }
`;

class CodeForm extends Component<PropsT, StateT> {
  state = {
    intervalId: null,
    secondsUntilEnableResendCode: 60,
    newCodeWasSent: false
  };

  componentDidMount() {
    const intervalId: IntervalID = setInterval(this.countDown, 1000);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  countDown = () => {
    let newCount = this.state.secondsUntilEnableResendCode - 1;
    if (newCount >= 0) {
      this.setState({ secondsUntilEnableResendCode: newCount });
    } else if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  };

  sendNewCode = () => {
    if (this.state.secondsUntilEnableResendCode === 0) {
      this.props.sendNewCode(true);
      this.setState({ newCodeWasSent: true });
    }
  };

  render() {
    const { displayTenant, email, showEditLink, submitting, handleSubmit, translate } = this.props;
    const { secondsUntilEnableResendCode, newCodeWasSent } = this.state;
    return (
      <FrameContainer>
        <Logo />
        <Helmet>
          <title>{`${translate('AUTH_SIGN_IN_NAV_LINK')} - ${displayTenant.name}`}</title>
        </Helmet>
        <Title>{translate('AUTH_GET_STARTED')}</Title>
        <Subtitle>
          {translate('AUTH_AN_AUTHENTICATION_CODE_WAS_SENT_TO')} <Email>{email} </Email>
          {showEditLink && (
            <EditLink to={`${AUTH_PATH}${GET_STARTED_BY_EMAIL_PATH}`}>
              <IoAndroidCreate />
            </EditLink>
          )}
        </Subtitle>
        <form onSubmit={handleSubmit} noValidate>
          <div className="cp-c-padding-2 cp-c-sm-padding-h-3">
            <div>
              <Field
                name="code"
                autoFocus
                label={translate('AUTH_CODE')}
                // $FlowFixMe
                component={BasicInput}
                type="number"
                placeholder={translate('AUTH_CODE_PLACEHOLDER')}
                disabled={submitting}
              />
            </div>
            <div>
              <SubmitButton type="submit" loading={submitting}>
                {translate('COMMON__CONFIRM')}
              </SubmitButton>
            </div>
            {!newCodeWasSent && (
              <CenteredText>
                <SendNewCode disabled={secondsUntilEnableResendCode > 0} onClick={this.sendNewCode}>
                  {translate('AUTH_SEND_NEW_CODE')}{' '}
                  {secondsUntilEnableResendCode > 0 &&
                    `00:${padStart(secondsUntilEnableResendCode.toString(), 2, '0')}`}
                </SendNewCode>
              </CenteredText>
            )}
          </div>
        </form>
      </FrameContainer>
    );
  }
}

export default injectI18n(CodeForm);
