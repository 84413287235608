// @flow

import DynamicImport from "@hypercharge/hyper-react-base/lib/common/dynamic-import";
import { isEqual } from "lodash";
import React, { Component } from "react";
import styled from "styled-components";

const Container = styled.div`
  &.ql-container.ql-container {
    border: 0;

    .ql-editor {
      padding: 6px;
    }
  }
`;

type RichTextEditorDisplayPropsT = {
  quillDeltaToHtml: any,
  value: Object
};

type StateT = {
  html: string
};

class RichTextEditorDisplay extends Component<
  RichTextEditorDisplayPropsT,
  StateT
> {
  constructor(props: RichTextEditorDisplayPropsT) {
    super(props);
    this.state = {
      html: this.getHtml(props)
    };
  }

  componentDidUpdate(prevProps: RichTextEditorDisplayPropsT) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.setState({ html: this.getHtml(this.props) });
    }
  }

  getHtml = (props: RichTextEditorDisplayPropsT) => {
    const { QuillDeltaToHtmlConverter } = props.quillDeltaToHtml;
    const converter = new QuillDeltaToHtmlConverter(props.value.ops, {});
    return converter.convert();
  };

  render() {
    return (
      <Container className="ql-container ql-snow">
        <div className="ql-editor">
          <div dangerouslySetInnerHTML={{ __html: this.state.html }} />
        </div>
      </Container>
    );
  }
}

type RichTextDisplayPropsT = {
  value: Object | null
};

const RichTextDisplay = ({ value }: RichTextDisplayPropsT) =>
  value ? (
    <DynamicImport load={() => import("quill-delta-to-html")}>
      {quillDeltaToHtml => (
        <RichTextEditorDisplay
          quillDeltaToHtml={quillDeltaToHtml}
          value={value}
        />
      )}
    </DynamicImport>
  ) : (
    "\u00a0"
  );

export default RichTextDisplay;
