// @flow

import { reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { parse, stringify } from 'query-string';
import { omit } from 'lodash';
import { push } from '@hypercharge/hyper-react-base/lib/router';
import {
  createValidator,
  required,
  email
} from '@hypercharge/hyper-react-base/lib/form/validation';
import { AUTH_PATH, GET_STARTED_CODE_PATH, AUTH_EMAIL_PARAM } from '../../constants.js';
import { getDisplayTenant } from '../../selectors.js';
import GetStartedByEmailForm from './GetStartedByEmailForm.js';

import type { Dispatch } from 'redux';
import type { LocationShape } from 'react-router-dom';
import type { MapOfFieldValidationsT } from '@hypercharge/hyper-react-base/lib/form/validation';
import type { GlobalStateT } from '../../../common/reducers/reducers.js';
import type { DisplayTenant } from '../../types.js';

type PropsT = {
  // injected by react-router
  location: LocationShape
};

type ConnectedStatePropsT = {
  initialValues: { email?: string },
  displayTenant: DisplayTenant
};

type GetStartedByEmailFormT = {
  email: string
};

export const form = 'get-started-by-email';

const formFields: MapOfFieldValidationsT = {
  email: [required, email]
};

const validate = createValidator(formFields);

const onSubmit = (
  { email }: GetStartedByEmailFormT,
  dispatch: Dispatch<*>,
  { location: { search } }: PropsT
) =>
  dispatch(
    push({
      pathname: `${AUTH_PATH}${GET_STARTED_CODE_PATH}`,
      search: stringify(omit(parse(search), [AUTH_EMAIL_PARAM])),
      state: { email }
    })
  );

function mapStateToProps(s: GlobalStateT, { location: { search } }: PropsT): ConnectedStatePropsT {
  const email = parse(search)[AUTH_EMAIL_PARAM];
  return {
    initialValues: email ? { email } : {},
    displayTenant: getDisplayTenant(s)
  };
}

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form,
    onSubmit,
    validate,
    destroyOnUnmount: false
  })
)(GetStartedByEmailForm);
