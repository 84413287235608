// @flow

import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { isGTE } from '@hypercharge/hyper-react-base/lib/browser';
import { getBrowserState } from '../../common/reducers/rootSelector.js';
import DesktopFooter from './DesktopFooter.js';
import MobileFooter from './MobileFooter.js';

import type { GlobalStateT } from '../../common/reducers/reducers.js';
import { getDisplayGdprConfig } from '../../me/selectors';
import type { DisplayGdprConfig } from '@hypercharge/portal-utils';

type ConnectedStatePropsT = {
  isMobile: boolean,
  displayGdprConfig: ?DisplayGdprConfig
};

type PropsT = ConnectedStatePropsT;

const currentYear = new Date().getFullYear();

const FooterContainer = ({ isMobile, displayGdprConfig }: PropsT) => {
  const FooterComponent = isMobile ? MobileFooter : DesktopFooter;
  return (
    <FooterComponent
      currentYear={currentYear}
      publicWebsiteUrlTitle={get(displayGdprConfig, 'publicWebsiteUrlTitle')}
    />
  );
};

function mapStateToProps(s: GlobalStateT): ConnectedStatePropsT {
  return {
    isMobile: !isGTE(getBrowserState(s), 'md'),
    displayGdprConfig: getDisplayGdprConfig(s)
  };
}

export default connect(mapStateToProps)(FooterContainer);
