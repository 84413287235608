const messages = {
  AUTH_UNAVAILABLE:
    'Malheureusement le service est indisponible en ce moment. Réessayer plus tard svp.',
  AUTH_SIGN_IN_NAV_LINK: 'Connexion',
  AUTH_LOG_OUT_NAV_LINK: 'Déconnexion',
  AUTH_GET_STARTED: 'Démarrer',
  AUTH_GET_STARTED_BY_EMAIL_WITH_SIGNUP_SUBTITLE:
    'Entrer votre email pour vous inscrire ou pour vous connecter.',
  AUTH_GET_STARTED_BY_EMAIL_SUBTITLE: 'Entrer votre email pour vous connecter.',
  AUTH_CODE: 'Code',
  AUTH_CODE_PLACEHOLDER: 'Entrez votre code...',
  AUTH_AN_AUTHENTICATION_CODE_WAS_SENT_TO: 'Un code d’indentification a été envoyé à',
  AUTH_SEND_NEW_CODE: 'Envoyer nouveau code',
  AUTH_A_NEW_CODE_WAS_SENT: 'Un nouveau code a été envoyé!',
  AUTH_CHOOSE_ACCOUNT: 'Choisissez votre compte',
  AUTH_MULTIPLE_ACCOUNTS_ARE_LINKED_TO: 'Plusieurs comptes sont liés à',
  AUTH_SOMETHING_WENT_WRONG: 'Une erreur s’est produite.',
  AUTH_REQUEST_CODE_ERROR_MESSAGE:
    'Il y a eu un problème qui requiert un code d’accès. Veuillez-svp réessayer plus tard.',
  AUTH_EMAIL: 'Email'
};

export default messages;
