// @flow

import { Component } from 'react';
import { connect } from 'react-redux';
import { fetchDisplayTenant } from '../../actions.js';
import { getDisplayTenant, getDisplayTenantStatus } from '../../selectors.js';

import type { Element } from 'react';
import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { FetchStatusT } from '@hypercharge/hyper-react-base/lib/types';
import type { GlobalStateT } from '../../../common/reducers/reducers.js';
import type { DisplayTenant } from '../../types.js';
import { TENANT_ID } from 'config';

type OwnPropsT = {
  render: (props: { displayTenant: DisplayTenant }) => Element<any>
};

type ConnectedStatePropsT = {
  displayTenant: ?DisplayTenant,
  displayTenantStatus: FetchStatusT
};

type ConnectedDispatchPropsT = {
  fetchDisplayTenant: (tenantId: string) => void
};

type PropsT = OwnPropsT & TranslatableT & ConnectedStatePropsT & ConnectedDispatchPropsT;

class DisplayTenantLoader extends Component<PropsT> {
  componentDidMount() {
    const { isAvailable, isPending } = this.props.displayTenantStatus;
    if (!isAvailable && !isPending) {
      this.props.fetchDisplayTenant(TENANT_ID);
    }
  }

  render() {
    const { displayTenant, displayTenantStatus, render } = this.props;

    if ((!displayTenant && !displayTenantStatus.isFailed) || displayTenantStatus.isPending) {
      return null;
    }

    return render({ displayTenant });
  }
}

function mapStateToProps(s: GlobalStateT): ConnectedStatePropsT {
  return {
    displayTenant: getDisplayTenant(s),
    displayTenantStatus: getDisplayTenantStatus(s)
  };
}

export default connect(
  mapStateToProps,
  { fetchDisplayTenant },
  null,
  { pure: false }
)(DisplayTenantLoader);
