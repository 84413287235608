// @flow

const messages = {
  GDPR_PORTAL: 'GDPR-portaal',
  MORE_INFO: 'Andere GDPR-gerelateerde vragen? ',
  CONTACT_US: 'Neem contact op',

  PRIVACY_POLICY: 'Privacybeleid',

  FAILED_ME_FETCH_MESSAGE: 'Helaas is er iets misgegaan bij het laden van uw gegevens.',
  ACCOUNT_EXPORT_DATA_TITLE: 'Exporteer uw gegevens',
  ACCOUNT_EXPORT_DATA_TEXT: 'Verzoek een export van al uw persoonlijke gegevens.',
  ACCOUNT_EXPORT_DATA_BUTTON: 'Exportgegevens opvragen',
  ACCOUNT_EXPORT_DATA_SUCCESS:
    'We hebben aanvraag ontgangen en verzenden een email met uw gegevens van zodra het export proces voltooid is.',
  ACCOUNT_EXPORT_DATA_ERROR: 'Het exporteren van uw gegevens is mislukt',
  ACCOUNT_DEACTIVATE_TITLE: 'Deactiveer je account',
  ACCOUNT_DEACTIVATE_TEXT:
    'Schakel uw account uit, verberg het voor alle personeelsleden en voorkom toekomstig gebruik van uw gegevens in alle activiteiten.',
  ACCOUNT_DEACTIVATE_BUTTON: 'Account deactivatie aanvraagen',
  ACCOUNT_DEACTIVATE_CONFIRM_TEXT: 'Doorgaan met het desactiveren van uw account?',
  ACCOUNT_DEACTIVATE_SUCCESS:
    'Uw account is gemarkeerd voor desactivering. We zullen u contacteren van zodra het proces voltooid is.',
  ACCOUNT_DEACTIVATE_ERROR: 'Het desactiveren van uw account is mislukt',

  GDPR_NEEDS_ACTIVATION: 'Helaas is het GDPR-portaal nog niet actief.',

  GDPR_NO_CHANGES: 'Er zijn geen wijzigingen aangebracht in uw informatie.',
  GDPR_CHANGES_WERE_REQUESTED:
    'Uw wijzigingen zijn succesvol ingediend en u ontvangt een melding zodra ze zijn verwerkt.',
  CORRECT_INVALID_FIELDS: 'Gelieve ongeldige velden te corrigeren'
};

export default messages;
