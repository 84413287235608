// @flow

import React from 'react';
import { head } from 'lodash';
import styled from 'styled-components';

const ListBullet = styled.div`
  color: #aaa;
  font-weight: 600;
  margin-right: 0.2rem;
`;

const ListElement = styled.div`
  padding-bottom: ${props => (props.listLength > 1 ? '0.3rem' : null)};
  text-overflow: ellipsis;
  overflow: hidden;

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

type PropsT = {
  value: any
};

const PlainTextDisplay = ({ value }: PropsT) => {
  if (value) {
    if (Array.isArray(value)) {
      return (
        value.map(val => (
          <ListElement key={val} className="cp-c-row" listLength={value.length}>
            <ListBullet className="cp-i-none">-</ListBullet>
            <div className="cp-i-flex">{val}</div>
          </ListElement>
        )) || '\u00a0'
      );
    } else {
      const lines = String(value).split('\n');
      return (
        <span title={value}>
          {lines.length > 1
            ? lines.map((item, key) => (
                <span key={key}>
                  {item}
                  <br />
                </span>
              ))
            : head(lines) || '\u00a0'}
        </span>
      );
    }
  } else {
    return '\u00a0';
  }
};

export default PlainTextDisplay;
