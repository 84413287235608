// @flow

const messages = {
  YES: 'Oui',
  NO: 'Non',
  REQUIRED: 'Obligatoire',
  SUBMIT_CHANGES: 'Soumettre les modifications',
  PROPERTY_FIELD_TYPE_link_TEXT_PLACEHOLDER: 'Texte à afficher',
  PROPERTY_FIELD_TYPE_link_LINK_PLACEHOLDER: 'Collez ou tapez le lien ici',
  VALIDATIONS__INVALID_PHONE_NUMBER: 'Numéro de téléphone invalide',
  EMPTY_VALUE: 'Vide'
};

export default messages;
