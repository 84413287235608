// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import ReactTestUtils from 'react-dom/test-utils';
import { Select } from 'antd';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';

import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';

type PropsT = {
  onChange: (value: any) => void,
  value: any,
  disabled: boolean,
  onKeyDown: (e: SyntheticEvent<*>) => void,
  autoFocus?: boolean
} & TranslatableT;

type StateT = {
  focused: boolean
};

const Wrapper = styled.div``;

class MultiTextEditor extends Component<PropsT, StateT> {
  state = {
    focused: false
  };

  render() {
    const { onChange, value, disabled, onKeyDown, autoFocus = true, translate } = this.props;

    return (
      <Wrapper onClick={e => e.stopPropagation()}>
        <Select
          ref={ref => {
            if (ref && autoFocus && !this.state.focused) {
              ref.focus(); // https://github.com/ant-design/ant-design/issues/8269
              const node: any = ReactDOM.findDOMNode(ref); // eslint-disable-line react/no-find-dom-node
              ReactTestUtils.Simulate.click(node);
            }
          }}
          showSearch={false}
          autoFocus={autoFocus}
          allowClear={!!value}
          style={{ width: '100%' }}
          filterOption={false}
          dropdownMatchSelectWidth={true}
          defaultActiveFirstOption={true}
          notFoundContent={null}
          placeholder={translate('ADD_MULTIPLE_TEXT_VALUES_PLACEHOLDER')}
          disabled={disabled}
          value={value ? value : undefined}
          onFocus={() => this.setState({ focused: true })}
          onChange={value => onChange(value || null)}
          mode="tags"
          onInputKeyDown={e => {
            if (onKeyDown && e.key !== 'Enter') {
              onKeyDown(e);
            }
          }}
        >
          {value && value.map(val => <Select.Option key={val}>{val}</Select.Option>)}
        </Select>
      </Wrapper>
    );
  }
}

export default injectI18n(MultiTextEditor);
