// @flow

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { get } from 'lodash';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { logIn } from '../../actions.js';
import { getDisplayTenant } from '../../selectors.js';
import { AUTH_PATH } from '../../constants.js';
import { handleFailure } from '../common/utils.js';
import ChooseAccount from './ChooseAccount.js';

import type { LocationShape } from 'react-router-dom';
import type { DispatchT } from '@hypercharge/hyper-react-base/lib/types';
import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { GlobalStateT } from '../../../common/reducers/reducers.js';
import type { DisplayTenant } from '../../types.js';

type OwnPropsT = {
  className?: string,
  // injected by react-router
  location: LocationShape
};

type ConnectedStatePropsT = {
  displayTenant: DisplayTenant
};

type ConnectedDispatchPropsT = {
  logIn: (tenantId: string, entityId: string) => Promise<any>,
  handleFailedLogin: () => void
};

type PropsT = OwnPropsT & TranslatableT & ConnectedStatePropsT & ConnectedDispatchPropsT;

const ChooseAccountContainer = ({ displayTenant, logIn, handleFailedLogin, location }: PropsT) =>
  get(location, 'state.email') && get(location, 'state.factorAccounts') ? (
    <ChooseAccount
      displayTenant={displayTenant}
      email={get(location, 'state.email')}
      factorAccounts={get(location, 'state.factorAccounts')}
      logIn={logIn}
      handleFailedLogin={handleFailedLogin}
    />
  ) : (
    <Redirect to={{ pathname: AUTH_PATH, search: location.search, hash: location.hash }} />
  );

const mapStateToProps = (s: GlobalStateT): ConnectedStatePropsT => ({
  displayTenant: getDisplayTenant(s)
});

const mapDispatchToProps = (
  dispatch: DispatchT,
  {
    location: {
      state: { email, code }
    },
    translate
  }: OwnPropsT & TranslatableT
): ConnectedDispatchPropsT => ({
  logIn: (tenantId, entityId) => dispatch(logIn(tenantId, entityId, email, code)),
  handleFailedLogin: () => {
    handleFailure(dispatch, translate);
  }
});

export default compose(
  injectI18n,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ChooseAccountContainer);
