// @flow

import React from 'react';
import styled from 'styled-components';

type PropsT = {
  currentYear: number,
  publicWebsiteUrlTitle: ?string
};

const Container = styled.div`
  background-color: ${props => props.theme.textColorLight};
  line-height: 1.3rem;
  text-align: center;
  font-size: 0.9rem;
  color: #fff;
  padding: 0.5rem;

  @media print {
    display: none;
  }
`;

const MobileFooter = ({ currentYear, publicWebsiteUrlTitle }: PropsT) =>
  !publicWebsiteUrlTitle ? null : (
    <Container>
      &copy; {currentYear} {publicWebsiteUrlTitle}
    </Container>
  );

export default MobileFooter;
