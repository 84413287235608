// @flow

import produce from 'immer';
import differenceInMilliseconds from 'date-fns/difference_in_milliseconds';
import { set, get } from 'lodash';
import { stringify } from 'query-string';
import { push } from '@hypercharge/hyper-react-base/lib/router/routerActions.js';
import { AUTH_PATH } from './constants.js';
import { requestAuthToken, refreshAuthToken } from './actions.js';
import {
  getDisplayTenant,
  getAuthJwt,
  getAuthRefreshToken,
  getIsApiAuthenticated,
  getIsUiAuthenticated,
  getAuthJwtExpiry,
  getEntityId
} from './selectors.js';

import type { Middleware } from 'redux';
import type { Location } from 'react-router';
import type { DispatchT, BaseActionT, ActionT } from '@hypercharge/hyper-react-base/lib/types';

type HttpAuthActionT = {
  ...BaseActionT,
  meta: {
    [otherKey: string]: any,
    http: { headers: Object }
  }
};

type AuthConfigT = {
  redirectPathOnLogout?: string
};

type PendingActionObjectT = {
  action: HttpAuthActionT,
  resolve: Function
};

// timeoutid is an object in node so
// cant initialize // reset to 0
let tokenRefreshTimer: TimeoutID;
let timerActive = false;
const stopTokenRefresh = () => {
  clearTimeout(tokenRefreshTimer);
  timerActive = false;
};
const scheduleTokenRefresh = (getState, dispatch, force) => {
  if (!timerActive || force) {
    const expiry = getAuthJwtExpiry(getState());
    if (expiry) {
      const timeout = differenceInMilliseconds(expiry, new Date()) - 60000;
      if (timeout > 0) {
        clearTimeout(tokenRefreshTimer);
        tokenRefreshTimer = setTimeout(() => {
          dispatch(refreshAuthToken());
        }, timeout);
        timerActive = true;
      }
    }
  }
};

const authMiddlewareFactory = (config: AuthConfigT) => {
  let isAuthenticating = false;
  const pendingActions: PendingActionObjectT[] = [];

  const nextWithAuthHeader = (getState, dispatch, next, action: HttpAuthActionT) => {
    const s = getState();
    const jwt = getAuthJwt(s);
    const isAuthenticated = getIsApiAuthenticated(s);
    const isUiAuthenticated = getIsUiAuthenticated(s);
    const refreshToken = getAuthRefreshToken(s);
    const entityId = getEntityId(s);

    if (entityId != null && (!isUiAuthenticated || !isAuthenticated)) {
      if (!isUiAuthenticated) {
        // Invalid refresh token
        const { pathname, search, hash }: Location = ((window.location: any): Location);
        const queryParams = { redirect: `${pathname}${search}${hash}` };
        dispatch(
          push({
            pathname: AUTH_PATH,
            search: stringify(queryParams)
          })
        );
      } else {
        // current accesss token has expired
        const promise = new Promise((resolve, reject) => {
          pendingActions.push({ action, resolve });
        });
        if (!isAuthenticating) {
          isAuthenticating = true;
          dispatch(requestAuthToken(get(getDisplayTenant(s), 'id', ''), entityId, refreshToken));
        }
        return promise;
      }
    } else if (jwt) {
      const newAction: HttpAuthActionT = produce(action, draft => {
        set(draft, 'meta.http.headers.Authorization', `Bearer ${jwt}`);
      });
      return next(newAction);
    }
    return next(action);
  };

  const resumeHttpActions = (getState, dispatch, next) => {
    pendingActions.forEach(({ action, resolve }: PendingActionObjectT) => {
      resolve(nextWithAuthHeader(getState, dispatch, next, action));
    });
    pendingActions.length = 0;
  };

  const authMiddleware: Middleware<any, ActionT, DispatchT> = ({
    getState,
    dispatch
  }) => next => action => {
    scheduleTokenRefresh(getState, dispatch);

    if (action.type === 'AUTH__LOG_OUT') {
      // TODO: should this be on AUTH__LOG_OUT_SUCCESS instead?
      const nextReturn = next(action);
      dispatch(push(config.redirectPathOnLogout || '/')); // TODO: check if it conflicts with redirectIfNotAuthenticated
      stopTokenRefresh();
      return nextReturn;
    } else if (action.type == 'AUTH__FORCE_REFRESH_AUTH_TOKEN') {
      const s = getState();
      dispatch(
        requestAuthToken(
          get(getDisplayTenant(s), 'id', ''),
          getEntityId(s) || '',
          getAuthRefreshToken(s)
        )
      );
    } else if (
      ['AUTH__REFRESH_AUTH_TOKEN_SUCCESS', 'AUTH__VERIFY_CODE_SUCCESS'].includes(action.type)
    ) {
      const nextReturn = next(action);
      // IMPORTANT: next(action) dispatched the action, resulting in the auth reducer including the authentication details in the state.
      // Therefore calling getState() will give you a state containing jwt info.
      isAuthenticating = false;
      resumeHttpActions(getState, dispatch, next);
      scheduleTokenRefresh(getState, dispatch, true);
      return nextReturn;
    } else if (action.type == 'AUTH__REFRESH_AUTH_TOKEN_FAIL') {
      setTimeout(() => {
        scheduleTokenRefresh(getState, dispatch, true);
      }, 2000);
    } else if (action.meta && action.meta.auth !== false && action.meta.http) {
      return nextWithAuthHeader(getState, dispatch, next, action);
    }
    return next(action);
  };

  return authMiddleware;
};

export default authMiddlewareFactory;
