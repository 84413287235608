// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { configureStore, history } from './common/root/store.js';
import Root from './common/root/Root.js';
import initGlobalStyles from './globalStyles.js';

initGlobalStyles();

const { store, persistor } = configureStore();

ReactDOM.render(
  <Root store={store} persistor={persistor} history={history} />,
  document.getElementById('react-app')
);

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept('./common/root/Root.js', () => {
      const NewRoot = require('./common/root/Root.js').default; // eslint-disable-line global-require
      ReactDOM.render(
        <NewRoot store={store} persistor={persistor} history={history} />,
        document.getElementById('react-app')
      );
    });
  }
}
