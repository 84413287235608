// @flow

import { ErrorBoundary } from "@hypercharge/hyper-react-base/lib/common/error-boundary";
import { injectI18n } from "@hypercharge/hyper-react-base/lib/i18n";
import { debounce, flatMap, forEach, groupBy, map } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import styled from "styled-components";
import { fetchItemsById } from "../../../actions.js";
import { getItem, isItemAvailable, isItemPending } from "../../../selectors.js";
import { getItemRepresentation } from "../utils.js";

import type { TranslatableT } from "@hypercharge/hyper-react-base/lib/i18n";
import type { GlobalStateT } from "../../../../../common/reducers/reducers.js";
import type { Item, EntityMeta } from "../../../types.js";

type OwnPropsT = {
  value: any,
  meta: EntityMeta
};

type ConnectedStatePropsT = {
  getItem: (id: string) => ?Item,
  isItemAvailable: (id: string) => boolean,
  isItemPending: (id: string) => boolean
};

type FetchItemsById = (
  definitionId: string,
  ids: string[],
  language: string
) => Promise<any>;

type ConnectedDispatchPropsT = {
  fetchItemsById: FetchItemsById
};

type PropsT = OwnPropsT &
  ConnectedStatePropsT &
  ConnectedDispatchPropsT &
  TranslatableT;

const EntityDisplay = ({
  value,
  meta: { definitionId },
  fetchItemsById,
  isItemAvailable,
  isItemPending,
  getItem,
  isFeaturedEntity,
  isEntityDisplayDataLoading,
  translate,
  language
}: PropsT) => {
  const [validatedAvailability, setValidatedAvailability] = useState<boolean>(
    false
  );
  useEffect(
    () => {
      if (value) {
        const ids = Array.isArray(value) ? value : [value];
        ids.filter(id => !isItemAvailable(id)).forEach(id => {
          addToQueueAndFetch(definitionId, id, language, fetchItemsById);
        });
        setValidatedAvailability(true);
      }
    },
    [value, definitionId, language, isItemAvailable, fetchItemsById]
  );

  let itemsJsx = null;
  if (value) {
    const values = Array.isArray(value) ? value : [value];
    const valueListBulletJsx = values.length > 1 && (
      <ScListBullet>-</ScListBullet>
    );
    itemsJsx = flatMap(
      (values.map(id => {
        const item = getItem(id);
        if (item) {
          return (
            <div key={id}>
              {valueListBulletJsx}
              {getItemRepresentation(item)}
            </div>
          );
        } else if (
          !validatedAvailability ||
          itemsToFetch.has(JSON.stringify({ definitionId, id, language })) ||
          isItemPending(id)
        ) {
          return (
            <span key={id}>
              {valueListBulletJsx}
              ...
            </span>
          );
        }
        return (
          <span key={id}>
            {valueListBulletJsx}
            <StyledId>{id}</StyledId>
          </span>
        );
      }): any),
      (a, i) => (i ? [<br key={i} />, a] : [a])
    );
    if (itemsJsx.length == 0) {
      itemsJsx = "\u00a0";
    }
  }

  return itemsJsx ? <ErrorBoundary>{itemsJsx}</ErrorBoundary> : "\u00a0";
};

const mapStateToProps = (s: GlobalStateT): ConnectedStatePropsT => ({
  getItem: id => getItem(s, id),
  isItemAvailable: id => isItemAvailable(s, id),
  isItemPending: id => isItemPending(s, id)
});

export default compose(
  injectI18n,
  connect(
    mapStateToProps,
    { fetchItemsById }
  )
)(EntityDisplay);

//
// Utils
//

const ScListBullet = styled.span`
  color: #aaa;
  font-weight: 600;
  margin-right: 0.2rem;
`;
const StyledId = styled.span`
  font-family: Monaco, Consolas, "Courier New", monospace;
  font-size: 0.8rem;
  line-height: 0.9rem;
  font-weight: 500;
  color: ${props => props.theme.textColorLight};
`;

const itemsToFetch = new Set();

const fetchFromQueue = (fetchItemsById: FetchItemsById) => {
  const itemsToFetchInfo = [...itemsToFetch].map(info => JSON.parse(info));
  forEach(groupBy(itemsToFetchInfo, "language"), (infoByLanguage, language) => {
    forEach(
      groupBy(infoByLanguage, "definitionId"),
      (infoByLanguageAndDefinitionId, definitionId) => {
        const ids = map(infoByLanguageAndDefinitionId, "id");
        fetchItemsById(definitionId, ids, language);
      }
    );
  });
  itemsToFetch.clear();
};

const debouncedFetchItemsById = debounce(fetchFromQueue);

const addToQueueAndFetch = (
  definitionId: string,
  id: string,
  language: string,
  fetchItemsById: FetchItemsById
) => {
  itemsToFetch.add(JSON.stringify({ definitionId, id, language }));
  debouncedFetchItemsById(fetchItemsById);
};
