// @flow

import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { AppNotifications } from '@hypercharge/hyper-react-base/lib/notifications';
import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';

import type { Node } from 'react';

type PropsT = {
  title: ?string,
  header: Node,
  content: Node,
  footer: Node,
  notifications: any
};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
const Table = styled.div`
  display: table;
  width: 100%;
  height: 100%;
`;
const Row = styled.div`
  display: table-row;
`;
const RowMiddle = Row.extend`
  height: 100%;
`;
const Cell = styled.div`
  display: table-cell;
  max-width: 0;
  background: #fafbfc;
`;
const Header = Cell.extend`
  border-bottom: 1px solid #eaeaea;

  @media print {
    display: none !important;
  }
`;

const AppLayout = ({ title, header, content, footer, notifications }: PropsT) => (
  <Fragment>
    {title && (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    )}
    <Wrapper>
      <Table>
        {header && (
          <Row>
            <Header>{header}</Header>
          </Row>
        )}
        {content && (
          <RowMiddle>
            <ErrorBoundary>
              <Cell id="app-content-cell">{content}</Cell>
            </ErrorBoundary>
          </RowMiddle>
        )}
        {footer && (
          <Row>
            <Cell>{footer}</Cell>
          </Row>
        )}
      </Table>
      <AppNotifications notifications={notifications} position="tr" />
    </Wrapper>
  </Fragment>
);

export default AppLayout;
