// @flow

import React, { Component } from 'react';
import { Select, Spin } from 'antd';
import styled from 'styled-components';
import {
  get,
  compact,
  debounce,
  groupBy,
  forEach,
  map,
  isEqual,
  find,
  pickBy,
  negate,
  isFunction
} from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { InputContainer } from '@hypercharge/hyper-react-base/lib/form';
import { searchItems, fetchItemsById } from '../../../actions.js';
import { getItem, isItemAvailable, isItemPending, isItemFailed } from '../../../selectors.js';
import { getItemRepresentation } from '../utils.js';

import type { DispatchT } from '@hypercharge/hyper-react-base/lib/types';
import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { GlobalStateT } from '../../../../../common/reducers/reducers.js';
import type { EntityMeta, Item } from '../../../types.js';

type OwnPropsT = {
  onChange: (value: any) => void,
  value: any,
  meta: EntityMeta,
  disabled: boolean
};

const Option = Select.Option;
const fullWidthStyle = { width: '100%' };
const DEFAULT_SELECT_OPTIONS_SIZE = 6;
export const MAX_CMS_ITEMS_LIMIT = 500;

type StyledInputContainerPropsT = { fetchingItems: boolean };
const StyledInputContainer = styled(InputContainer)`
  .ant-select-selection-selected-value {
    text-overflow: ${(props: StyledInputContainerPropsT) => (props.fetchingItems ? 'unset' : null)};
  }
`;

type ConnectedStatePropsT = {
  selectedItems: Item[],
  unavailableSelectedEntityIds: string[],
  pendingSelectedEntityIds: string[],
  failedSelectedEntityIds: string[]
};

type FetchItemsById = (definitionId: string, ids: string[], languageCode: string) => Promise<any>;

type ConnectedDispatchPropsT = {
  searchItems: (
    textSearchQuery: string,
    numberOfSelectedItems: number
  ) => Promise<{
    results: Item[],
    totalCount: number
  }>,
  fetchItemsById: FetchItemsById
};

type PropsT = OwnPropsT & TranslatableT & ConnectedStatePropsT & ConnectedDispatchPropsT;

type StateT = {
  results: any,
  searchingItems: boolean,
  textSearchQuery: ?string
};

const itemsToFetch = new Set();

const fetchFromQueue = (fetchItemsById: FetchItemsById) => {
  const itemsToFetchInfo = [...itemsToFetch].map(info => JSON.parse(info));
  forEach(groupBy(itemsToFetchInfo, 'language'), (infoByLanguage, language) => {
    forEach(
      groupBy(infoByLanguage, 'definitionId'),
      (infoByLanguageAndDefinitionId, definitionId) => {
        const ids = map(infoByLanguageAndDefinitionId, 'id');
        fetchItemsById(definitionId, ids, language);
      }
    );
  });
  itemsToFetch.clear();
};

const debouncedFetchItemsById = debounce(fetchFromQueue);

const addToQueueAndFetch = (
  definitionId: string,
  id: string,
  language: string,
  fetchItemsById: FetchItemsById
) => {
  itemsToFetch.add(JSON.stringify({ definitionId, id, language }));
  debouncedFetchItemsById(fetchItemsById);
};

class EntityEditor extends Component<PropsT, StateT> {
  unmounted = false;

  state = {
    results: [],
    searchingItems: false,
    textSearchQuery: ''
  };

  componentDidMount() {
    this.fetchSelectedItems();
  }

  shouldComponentUpdate(nextProps: PropsT, nextState: StateT) {
    const nonFunctionalProps = pickBy(this.props, negate(isFunction));
    const nextNonFunctionalProps = pickBy(nextProps, negate(isFunction));
    return this.state !== nextState || !isEqual(nonFunctionalProps, nextNonFunctionalProps);
  }

  componentDidUpdate(prevProps: PropsT) {
    if (!isEqual(this.props.value, prevProps.value)) {
      this.fetchSelectedItems();
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  search = (textSearchQuery: ?string) => {
    if (textSearchQuery !== this.state.textSearchQuery) {
      this.setState({ textSearchQuery, searchingItems: true });
      this.props
        .searchItems(textSearchQuery || '', this.props.selectedItems.length)
        .then(({ results }) => {
          if (!this.unmounted) {
            this.setState({ results, searchingItems: false });
          }
        });
    }
  };

  fetchSelectedItems = () => {
    const { meta, language } = this.props;
    this.props.unavailableSelectedEntityIds.forEach(entityId => {
      addToQueueAndFetch(meta.definitionId, entityId, language, this.props.fetchItemsById);
    });
  };

  searchForEntities = debounce(this.search, 200);

  getOptionJsx = (item: Item) => <Option key={item.entityId}>{getItemRepresentation(item)}</Option>;

  render() {
    const { results, searchingItems } = this.state;
    const {
      selectedItems,
      pendingSelectedEntityIds,
      unavailableSelectedEntityIds,
      failedSelectedEntityIds
    } = this.props;
    const missingSelectedEntityIds = [...unavailableSelectedEntityIds, ...pendingSelectedEntityIds];
    const fetchingItems = missingSelectedEntityIds.length > 0;
    return (
      <StyledInputContainer onClick={e => e.stopPropagation()} fetchingItems={fetchingItems}>
        <Select
          showSearch
          allowClear={!!this.props.value}
          style={fullWidthStyle}
          filterOption={false}
          dropdownMatchSelectWidth={true}
          notFoundContent={searchingItems ? <Spin size="small" /> : null}
          onSearch={this.searchForEntities}
          disabled={this.props.disabled || fetchingItems}
          value={this.props.value || undefined}
          onFocus={this.searchForEntities}
          onChange={value => {
            this.props.onChange(value || null);
          }}
          onSelect={value => {
            this.searchForEntities();
          }}
          mode={this.props.meta.list ? 'multiple' : null}
        >
          {missingSelectedEntityIds.map(entityId => (
            <Option key={entityId}>
              <Spin size="small" />
            </Option>
          ))}
          {failedSelectedEntityIds.map(entityId => (
            <Option key={entityId}>{entityId}</Option>
          ))}
          {!searchingItems && selectedItems.map(this.getOptionJsx)}
          {!searchingItems &&
            results
              .filter(({ entityId }) => !find(selectedItems, { entityId }))
              .map(this.getOptionJsx)}
        </Select>
      </StyledInputContainer>
    );
  }
}

const mapStateToProps = (s: GlobalStateT, { value }: OwnPropsT): ConnectedStatePropsT => {
  const entityIds: string[] = value ? (Array.isArray(value) ? value : [value]) : [];
  return {
    selectedItems: compact(entityIds.map(entityId => getItem(s, entityId))),
    unavailableSelectedEntityIds: entityIds.filter(entityId => !isItemAvailable(s, entityId)),
    pendingSelectedEntityIds: entityIds.filter(entityId => isItemPending(s, entityId)),
    failedSelectedEntityIds: entityIds.filter(entityId => isItemFailed(s, entityId))
  };
};

const mapDispatchToProps = (
  dispatch: DispatchT,
  { meta, language }: OwnPropsT & TranslatableT
) => ({
  searchItems: (textSearchQuery, numberOfSelectedItems) =>
    dispatch(
      searchItems(meta.definitionId, {
        sortBy: [{ field: 'entityId', order: 'DESC' }],
        query: { condition: 'OR', filters: [] },
        fullText: textSearchQuery || '',
        offset: 0,
        languageCode: language,
        ...(meta.extraPayloadProps || {}),
        limit: Math.min(
          MAX_CMS_ITEMS_LIMIT,
          get(meta, 'extraPayloadProps.limit', DEFAULT_SELECT_OPTIONS_SIZE) + numberOfSelectedItems
        )
      })
    ),
  fetchItemsById: (definitionId, ids, languageCode) =>
    dispatch(fetchItemsById(definitionId, ids, languageCode))
});

export default compose(
  injectI18n,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EntityEditor);
