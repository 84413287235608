// @flow

import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { AuthRoutes, AUTH_PATH, getDisplayTenant } from '../../auth';
import Header from '../../header';
import FooterContainer from '../../footer/components/FooterContainer.js';
import { ME_PATH, RestrictedMePortal } from '../../me';
import Frame, { FrameContainer, Title } from '../components/frame';
import Logo from '../components/logo';
import { getNotificationsState } from '../reducers/rootSelector.js';
import AppLayout from './AppLayout.js';

import type { Notification } from '@hypercharge/hyper-react-base/lib/notifications';
import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { GlobalStateT } from '../reducers/reducers.js';
import type { DisplayTenant } from '../../auth';

type ConnectedStatePropsT = {
  notifications: Notification[],
  title: ?string,
  gdprNeedsActivation: boolean
};

type PropsT = ConnectedStatePropsT & TranslatableT;

const App = ({ title, notifications, gdprNeedsActivation, location, translate }: PropsT) =>
  gdprNeedsActivation ? (
    <Frame>
      <FrameContainer>
        <Logo />
        <Title>{translate('GDPR_NEEDS_ACTIVATION')}</Title>
      </FrameContainer>
    </Frame>
  ) : (
    <AppLayout
      title={title}
      header={
        <Switch>
          <Route path={AUTH_PATH} render={() => null} />
          <Route component={Header} />
        </Switch>
      }
      content={
        <Switch>
          <Route path={AUTH_PATH} component={AuthRoutes} />
          <Route path={ME_PATH} component={RestrictedMePortal} />
          <Redirect to={ME_PATH} />
        </Switch>
      }
      notifications={notifications}
      footer={<Route component={FooterContainer} />}
    />
  );

function mapStateToProps(s: GlobalStateT): ConnectedStatePropsT {
  const displayTenant: DisplayTenant = getDisplayTenant(s);
  return {
    notifications: getNotificationsState(s),
    title: get(displayTenant, 'name'),
    gdprNeedsActivation: !get(displayTenant, 'gdpr')
  };
}

export default compose(
  injectI18n,
  withRouter,
  connect(mapStateToProps)
)(App);
