// @flow

const messages = {
  GDPR_PORTAL: 'GDPR Portal',
  MORE_INFO: 'Other GDPR related requests?',
  CONTACT_US: 'Contact us',

  FAILED_ME_FETCH_MESSAGE: 'Unfortunately something went wrong while fetching your information.',

  PRIVACY_POLICY: 'Privacy policy',

  ACCOUNT_EXPORT_DATA_TITLE: 'Export your data',
  ACCOUNT_EXPORT_DATA_TEXT: 'Request an export of all your personal data.',
  ACCOUNT_EXPORT_DATA_BUTTON: 'Request export data',
  ACCOUNT_EXPORT_DATA_SUCCESS:
    'Your data is exporting. You will receive an email with your data when the process is complete.',
  ACCOUNT_EXPORT_DATA_ERROR: 'Failed to export your data',
  ACCOUNT_DEACTIVATE_TITLE: 'Deactivate your account',
  ACCOUNT_DEACTIVATE_TEXT:
    'Disable your account, hiding it from all personnel and preventing future use of your data in any activity.',
  ACCOUNT_DEACTIVATE_BUTTON: 'Request account deactivation',
  ACCOUNT_DEACTIVATE_CONFIRM_TEXT: 'Proceed with account deactivation?',
  ACCOUNT_DEACTIVATE_SUCCESS:
    'Your account has been marked for deactivation. You will be contacted when the process is complete.',
  ACCOUNT_DEACTIVATE_ERROR: 'Failed to deactivate your account',

  GDPR_NEEDS_ACTIVATION: 'Unfortunately the GDPR portal is not yet active.',

  GDPR_NO_CHANGES: 'There were no changes made to your data.',
  GDPR_CHANGES_WERE_REQUESTED:
    'Your changes were successfully submitted and you will be notified once they are processed.',
  CORRECT_INVALID_FIELDS: 'Please correct invalid fields'
};

export default messages;
