// @flow

const messages = {
  AUTH_UNAVAILABLE: 'Unfortunately the service is unavailable right now. Please try again later.',
  AUTH_SIGN_IN_NAV_LINK: 'Sign in',
  AUTH_LOG_OUT_NAV_LINK: 'Log out',
  AUTH_GET_STARTED: 'Get started',
  AUTH_GET_STARTED_BY_EMAIL_WITH_SIGNUP_SUBTITLE: 'Enter your email to sign up or log in.',
  AUTH_GET_STARTED_BY_EMAIL_SUBTITLE: 'Enter your email to log in.',
  AUTH_CODE: 'Code',
  AUTH_CODE_PLACEHOLDER: 'Enter your code...',
  AUTH_AN_AUTHENTICATION_CODE_WAS_SENT_TO: 'An authentication code was sent to',
  AUTH_SEND_NEW_CODE: 'Send new code',
  AUTH_A_NEW_CODE_WAS_SENT: 'A new code was sent!',
  AUTH_CHOOSE_ACCOUNT: 'Choose account',
  AUTH_MULTIPLE_ACCOUNTS_ARE_LINKED_TO: 'Multiple accounts are linked to',
  AUTH_SOMETHING_WENT_WRONG: 'Something went wrong.',
  AUTH_REQUEST_CODE_ERROR_MESSAGE:
    'There was a problem requesting a login code. Please try again later.',
  AUTH_EMAIL: 'Email'
};

export default messages;
