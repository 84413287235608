// @flow

import { compose, createStore, applyMiddleware } from 'redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import { createBrowserHistory } from 'history';
import {
  persistStore,
  persistCombineReducers,
  createMigrate,
  createTransform
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { httpMiddleware } from '@hypercharge/hyper-react-base/lib/http';
import {
  bindLocationChange,
  routerMiddlewareFactory
} from '@hypercharge/hyper-react-base/lib/router';
import { authMiddlewareFactory, authEnhanceRootReducer } from '../../auth';
import { ME_PATH } from '../../me';
import reducers from '../reducers/reducers.js';

export const history: Object = createBrowserHistory();

const middlewares = [
  authMiddlewareFactory({ redirectPathOnLogout: ME_PATH }),
  httpMiddleware,
  routerMiddlewareFactory(history)
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// $FlowFixMe
const finalCreateStore = composeEnhancers(applyMiddleware(...middlewares), responsiveStoreEnhancer)(
  createStore
);

const migrations = {
  0: () => ({})
};

let blacklistAuthTransform = createTransform((inboundState, key) => {
  // TODO: since there is no new fetch being done of the displayTenant, evaluate if this can't go away
  if (key != 'auth') {
    return inboundState;
  } else {
    return {
      ...inboundState,
      displayTenantStatus: { pending: false, failed: false }
    };
  }
});

const persistConfig = {
  version: 1,
  key: 'app',
  storage: storageSession,
  whitelist: ['i18n', 'auth', '_persist'],
  transforms: [blacklistAuthTransform],
  debounce: 1000,
  migrate: createMigrate(migrations, { debug: false })
};

export const configureStore = () => {
  let rootReducer = persistCombineReducers(persistConfig, reducers);
  rootReducer = authEnhanceRootReducer(rootReducer, persistConfig);
  const store = finalCreateStore(rootReducer);
  const persistor = persistStore(store);

  bindLocationChange(store, history);

  return { persistor, store };
};
