// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { submitChanges } from '../../actions.js';
import MyContactForm from './MyContactForm.js';

import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { DispatchT } from '@hypercharge/hyper-react-base/lib/types';
import type { GlobalStateT } from '../../../common/reducers/reducers.js';
import type { DisplaySection, DisplayDataList } from '../../../cms';
import type { DisplayGdprConfig } from '@hypercharge/portal-utils';

type OwnPropsT = {
  me: DisplayDataList,
  displayGdprConfig: ?DisplayGdprConfig
};

type ConnectedStatePropsT = {
  data: DisplaySection[]
};

type ConnectedDispatchPropsT = {
  submitChanges: (item: Object) => Promise<any>
};

const mapStateToProps = (s: GlobalStateT, { me }: OwnPropsT): ConnectedStatePropsT => ({
  data: get(me, 'data', []).filter(({ sectionId }) => sectionId !== 'system')
});

const mapDispatchToProps = (
  dispatch: DispatchT,
  { language }: TranslatableT
): ConnectedDispatchPropsT => ({
  submitChanges: item => dispatch(submitChanges(item, language))
});

export default compose(
  injectI18n,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MyContactForm);
