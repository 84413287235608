// @flow

import { ErrorBoundary } from '@hypercharge/hyper-react-base/lib/common/error-boundary';
import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { getTranslation, injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Alert } from 'antd';
import { get } from 'lodash';
import React, { Component, Fragment } from 'react';
import FaExternalLink from 'react-icons/lib/fa/external-link';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import { redirectIfNotAuthenticated } from '../../auth';
import { fetchDisplayGdprConfig, fetchMe } from '../actions.js';
import {
  getDisplayGdprConfig,
  getDisplayGdprConfigStatus,
  getMe,
  getMeStatus
} from '../selectors.js';
import MyContactFormContainer from './my-contact-form/MyContactFormContainer.js';
import TenantInfoContainer from './tenant-info/TenantInfoContainer.js';

import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { FetchStatusT } from '@hypercharge/hyper-react-base/lib/types';
import type { GlobalStateT } from '../../common/reducers/reducers.js';
import type { DisplayDataList } from '../../cms';
import type { DisplayGdprConfig } from '@hypercharge/portal-utils';

type ConnectedStatePropsT = {
  me: ?DisplayDataList,
  meStatus: FetchStatusT,
  displayGdprConfig: ?DisplayGdprConfig,
  displayGdprConfigStatus: FetchStatusT
};

type ConnectedDispatchPropsT = {
  fetchMe: () => Promise<*>,
  fetchDisplayGdprConfig: () => Promise<*>
};

type PropsT = TranslatableT & ConnectedStatePropsT & ConnectedDispatchPropsT;

const Container = styled.div`
  box-sizing: border-box;
  max-width: 800px;
  margin: 0 auto;
`;
const ContentContainer = styled.div`
  min-height: 18.5rem;
`;
const FailedText = styled.div`
  padding: 1.5rem;
`;
const StyledAlert = styled(Alert)`
  margin: 1rem 1rem 0 !important;
`;
const LinkIcon = styled(FaExternalLink)`
  font-size: 0.8rem;
`;

class MePortal extends Component<PropsT> {
  componentDidMount() {
    if (!this.props.meStatus.isAvailable) {
      this.props.fetchMe();
    }
    if (!this.props.displayGdprConfigStatus.isAvailable) {
      this.props.fetchDisplayGdprConfig();
    }
  }

  render() {
    const {
      me,
      meStatus,
      displayGdprConfig,
      displayGdprConfigStatus,
      translate,
      language
    } = this.props;
    if (displayGdprConfigStatus.isPending) {
      return null;
    }
    const privacyPolicyText = getTranslation(
      get(displayGdprConfig, 'privacyPolicyFooter'),
      language
    );
    const privacyPolicyUrl = getTranslation(get(displayGdprConfig, 'privacyPolicyUrl'), language);
    return (
      <ErrorBoundary>
        <Container>
          {privacyPolicyText && (
            <StyledAlert
              message={
                <Fragment>
                  {privacyPolicyText}
                  {privacyPolicyUrl && (
                    <a href={privacyPolicyUrl} rel="noopener noreferrer" target="_blank">
                      {' '}
                      {translate('PRIVACY_POLICY')} <LinkIcon />
                    </a>
                  )}
                </Fragment>
              }
              closable
              type="info"
            />
          )}
          <div className="cp-c-padding-2">
            <div>
              <TenantInfoContainer />
            </div>
            <div>
              <SectionCard
                title={translate('PERSONAL_DATA')}
                withoutPadding
                loading={meStatus.isPending || displayGdprConfigStatus.isPending}
              >
                <ContentContainer>
                  {meStatus.isFailed ? (
                    <FailedText>{translate('FAILED_ME_FETCH_MESSAGE')}</FailedText>
                  ) : (
                    me && <MyContactFormContainer me={me} displayGdprConfig={displayGdprConfig} />
                  )}
                </ContentContainer>
              </SectionCard>
            </div>
          </div>
        </Container>
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(s: GlobalStateT, { language }: TranslatableT): ConnectedStatePropsT {
  return {
    me: getMe(s),
    meStatus: getMeStatus(s),
    displayGdprConfig: getDisplayGdprConfig(s),
    displayGdprConfigStatus: getDisplayGdprConfigStatus(s)
  };
}

export default compose(
  redirectIfNotAuthenticated,
  injectI18n,
  connect(
    mapStateToProps,
    { fetchDisplayGdprConfig, fetchMe }
  )
)(MePortal);
