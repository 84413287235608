// @flow
import { compose } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { getDisplayTenant } from '../../../auth';
import { getDisplayGdprConfig } from '../../selectors.js';
import TenantInfo from './TenantInfo.js';
import type { GlobalStateT } from '../../../common/reducers/reducers.js';
import { getTranslation } from '@hypercharge/hyper-react-base/lib/i18n';
import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
type ConnectedStatePropsT = {
  logoUrl: ?string,
  name: ?string,
  publicWebsiteUrl: ?string,
  publicWebsiteUrlTitle?: string
};

const mapStateToProps = (s: GlobalStateT, { language }: TranslatableT): ConnectedStatePropsT => {
  const displayTenant = getDisplayTenant(s);
  const displayGdprConfig = getDisplayGdprConfig(s);

  return {
    logoUrl: get(displayTenant, 'logoUrl'),
    name: get(displayTenant, 'name'),
    publicWebsiteUrl: getTranslation(get(displayGdprConfig, 'publicWebsiteUrl'), language),
    publicWebsiteUrlTitle: get(displayGdprConfig, 'publicWebsiteUrlTitle')
  };
};

export default compose(
  injectI18n,
  connect(mapStateToProps)
)(TenantInfo);
