// @flow

import isFuture from 'date-fns/is_future';
import { getAuthState } from '../common/reducers/rootSelector.js';

import type { FetchStatusT } from '@hypercharge/hyper-react-base/lib/types';
import type { GlobalStateT } from '../common/reducers/reducers.js';
import type { DisplayTenant } from './types.js';

export const getIsApiAuthenticated = (s: GlobalStateT): boolean => {
  const authState = getAuthState(s);
  return authState.jwt != null && authState.jwtExpiry != null && isFuture(authState.jwtExpiry);
};

export const getIsUiAuthenticated = (s: GlobalStateT): boolean => {
  const authState = getAuthState(s);
  return authState.refreshTokenExpiry != null && isFuture(authState.refreshTokenExpiry);
};

export const getAuthRefreshToken = (s: GlobalStateT): ?string => getAuthState(s).refreshToken;

export const getAuthJwt = (s: GlobalStateT): ?string => getAuthState(s).jwt;

export const getAuthJwtExpiry = (s: GlobalStateT): ?Date => getAuthState(s).jwtExpiry;

export const getEntityId = (s: GlobalStateT): ?string => getAuthState(s).entityId;

export const getDisplayTenant = (s: GlobalStateT): ?DisplayTenant => getAuthState(s).displayTenant;

export const getDisplayTenantStatus = (s: GlobalStateT): FetchStatusT => {
  const authState = getAuthState(s);
  return {
    isAvailable: !!authState.displayTenant,
    isPending: authState.displayTenantStatus.pending,
    isFailed: authState.displayTenantStatus.failed
  };
};
