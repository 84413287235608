// @flow

import type { GlobalStateT } from './reducers.js';
import type { StateT as I18nStateT } from '@hypercharge/hyper-react-base/lib/i18n/i18nReducerFactory';
import type { StateT as NotificationsStateT } from '@hypercharge/hyper-react-base/lib/notifications';
import type { StateT as BrowserStateT } from '@hypercharge/hyper-react-base/lib/browser';
import type { StateT as AuthStateT } from '../../auth';
import type { StateT as MeStateT } from '../../me';
import type { StateT as CmsStateT } from '../../cms';

export const getI18nState = (s: GlobalStateT): I18nStateT => s.i18n;

export const getNotificationsState = (s: GlobalStateT): NotificationsStateT => s.notifications;

export const getAuthState = (s: GlobalStateT): AuthStateT => s.auth;

export const getMeState = (s: GlobalStateT): MeStateT => s.me;

export const getBrowserState = (s: GlobalStateT): BrowserStateT => s.browser;

export const getCmsState = (s: GlobalStateT): CmsStateT => s.cms;
