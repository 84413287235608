// @flow

import React from 'react';
import styled from 'styled-components';
import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';

const PropertyInput = styled(BasicInput)`
  padding-bottom: 0 !important;
`;

type PropsT = {
  onChange: (value: any) => void,
  value: any,
  disabled: boolean,
  onKeyDown: (e: SyntheticEvent<*>) => void,
  autoFocus?: boolean
};

const NumberEditor = ({ value, onChange, disabled, onKeyDown, autoFocus = true }: PropsT) => (
  <PropertyInput
    type="number"
    input={{
      value: value === null ? '' : value
    }}
    autoFocus={autoFocus}
    onChange={e =>
      onChange(e.target.value && !isNaN(e.target.value) ? Number(e.target.value) : null)
    }
    disabled={disabled}
    onKeyDown={onKeyDown}
  />
);

export default NumberEditor;
