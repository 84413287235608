// @flow

import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';

import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';

type PropsT = {
  value: any
} & TranslatableT;

const CheckboxDisplay = ({ value, translate }: PropsT) => {
  switch (value) {
    case true:
      return translate('YES');
    case false:
      return translate('NO');
    default:
      return '\u00a0';
  }
};

export default injectI18n(CheckboxDisplay);
