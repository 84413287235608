// @flow

import React, { useState, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import LoadingIndicator from '../../../common/components/loading-indicator';
import Logo from '../../../common/components/logo';
import { FrameContainer, Title, Subtitle } from '../../../common/components/frame';
import { Email } from '../common/components.js';
import AccountRow from './AccountRow.js';

import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { FactorAccountT, DisplayTenant } from '../../types.js';

type PropsT = {
  className?: string,
  displayTenant: DisplayTenant,
  email: string,
  factorAccounts: FactorAccountT[],
  logIn: (tenantId: string, entityId: string) => Promise<any>,
  handleFailedLogin: () => void
} & TranslatableT;

const loadingAccountsContainerCss = css`
  border: 1px solid #d9e3ed;
  border-top: 0;
`;
const AccountsContainer = styled.div`
  position: relative;
  ${props => props.loading && loadingAccountsContainerCss};
`;

const ChooseAccount = ({
  className,
  displayTenant,
  logIn,
  handleFailedLogin,
  email,
  factorAccounts,
  translate
}: PropsT) => {
  const [clickedAccountId, setClickedAccountId] = useState();
  return (
    <Fragment>
      <FrameContainer className={className}>
        <Logo />
        <Helmet>
          <title>{`${translate('AUTH_SIGN_IN_NAV_LINK')} - ${displayTenant.name}`}</title>
        </Helmet>
        <Title>{translate('AUTH_CHOOSE_ACCOUNT')}</Title>
        <Subtitle>
          {translate('AUTH_MULTIPLE_ACCOUNTS_ARE_LINKED_TO')} <Email>{email}</Email>.
        </Subtitle>
      </FrameContainer>
      <AccountsContainer loading={!!clickedAccountId}>
        {clickedAccountId && <LoadingIndicator />}
        {factorAccounts.map((factorAccount: FactorAccountT) => (
          <AccountRow
            key={factorAccount.entityId}
            account={factorAccount}
            disabled={!!clickedAccountId}
            onClick={() => {
              setClickedAccountId(factorAccount.entityId);
              logIn(displayTenant.id, factorAccount.entityId).catch(() => {
                handleFailedLogin();
                setClickedAccountId();
              });
            }}
          />
        ))}
      </AccountsContainer>
    </Fragment>
  );
};

export default injectI18n(ChooseAccount);
