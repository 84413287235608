// @flow

import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { head, pickBy, negate, isFunction, isEqual } from 'lodash';
import { Menu, Dropdown } from 'antd';
import MdMenu from 'react-icons/lib/md/menu';
import MdArrowForward from 'react-icons/lib/md/arrow-forward';
import MdLanguage from 'react-icons/lib/md/language';
import MdRadioButtonChecked from 'react-icons/lib/md/radio-button-checked';
import MdRadioButtonUnchecked from 'react-icons/lib/md/radio-button-unchecked';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';

import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';

type PropsT = {
  className?: string,
  availableLanguages: string[],
  changeLanguage: (language: string) => void,
  signOut: () => void
} & TranslatableT;

const Button = styled(MdMenu)`
  cursor: pointer;
  font-weight: 500;
  color: ${props => props.theme.textColor};
  font-size: 1.6rem;
`;
const MenuText = styled.span`
  margin-left: 0.5rem;
`;

class MenuComponent extends Component<PropsT> {
  shouldComponentUpdate(nextProps: PropsT) {
    // TODO: evaluate if needed
    const nonFunctionalProps = pickBy(this.props, negate(isFunction));
    const nextNonFunctionalProps = pickBy(nextProps, negate(isFunction));
    return !isEqual(nonFunctionalProps, nextNonFunctionalProps);
  }

  handleMenuClicks = ({ keyPath }: { keyPath: string[] }) => {
    const { changeLanguage, signOut } = this.props;
    if (keyPath.includes('signOut')) {
      signOut();
    } else if (keyPath.includes('language')) {
      changeLanguage(head(keyPath));
    }
  };

  render() {
    const { className, availableLanguages, translate, language: currentLanguage } = this.props;
    return (
      <Dropdown
        className={className}
        trigger={['click']}
        placement="bottomRight"
        overlay={
          <Menu onClick={this.handleMenuClicks}>
            <Menu.SubMenu
              key="language"
              title={
                <Fragment>
                  <MdLanguage /> <MenuText>{translate('COMMON__LANGUAGE')}</MenuText>
                </Fragment>
              }
            >
              {availableLanguages.map(language => (
                <Menu.Item key={language} disabled={language === currentLanguage}>
                  <Link to="#">
                    {language === currentLanguage ? (
                      <MdRadioButtonChecked />
                    ) : (
                      <MdRadioButtonUnchecked />
                    )}
                    <MenuText>{translate(language)}</MenuText>
                  </Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
            <Menu.Divider />
            <Menu.Item key="signOut">
              <MdArrowForward /> <MenuText>{translate('AUTH_LOG_OUT_NAV_LINK')}</MenuText>
            </Menu.Item>
          </Menu>
        }
      >
        <Button />
      </Dropdown>
    );
  }
}

export default injectI18n(MenuComponent);
