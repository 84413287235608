// @flow

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get } from 'lodash';
import { i18nChangeLanguage } from '@hypercharge/hyper-react-base/lib/i18n';
import { signOut, getDisplayTenant } from '../../auth';
import Menu from './Menu.js';

import type { GlobalStateT } from '../../common/reducers/reducers.js';

type OwnPropsT = {
  className?: string
};

type ConnectedStatePropsT = {
  availableLanguages: string[]
};

type ConnectedDispatchPropsT = {
  i18nChangeLanguage: (language: string) => void,
  signOut: () => void
};

export type PropsT = OwnPropsT & ConnectedStatePropsT & ConnectedDispatchPropsT;

const MenuContainer = ({ className, availableLanguages, i18nChangeLanguage, signOut }: PropsT) => (
  <Menu
    className={className}
    availableLanguages={availableLanguages}
    changeLanguage={i18nChangeLanguage}
    signOut={signOut}
  />
);

function mapStateToProps(s: GlobalStateT): ConnectedStatePropsT {
  return {
    availableLanguages: get(getDisplayTenant(s), 'availableLanguages', [])
  };
}

export default compose(
  connect(
    mapStateToProps,
    { i18nChangeLanguage, signOut }
  )
)(MenuContainer);
