// @flow

import { injectI18n, getTranslation } from '@hypercharge/hyper-react-base/lib/i18n';

import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { NumberMeta } from '../../../types.js';

type PropsT = {
  value: any,
  meta: NumberMeta
} & TranslatableT;

const NumberDisplay = ({
  value,
  meta: { precision, units, useGrouping } = {},
  language
}: PropsT) => {
  return value !== '' && value !== null && !isNaN(value)
    ? `${Number(value).toLocaleString([], {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
        useGrouping: !!useGrouping
      })} ${getTranslation(units, language)}`
    : '\u00a0';
};

export default injectI18n(NumberDisplay);
