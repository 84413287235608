// @flow

import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { isEqual } from 'lodash';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import DynamicImport from '@hypercharge/hyper-react-base/lib/common/dynamic-import';

import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { RichTextMeta } from '../../../types.js';

const disabledContainerCss = css`
  .ql-editor {
    color: #919191;
  }
  .ql-toolbar,
  .ql-container {
    background: #f5f5f5;
  }
  .ql-toolbar button {
    cursor: default;
    pointer-events: none;

    .ql-fill {
      fill: #b3b3b3;
    }
    .ql-stroke {
      stroke: #b3b3b3;
    }
  }
`;
type ContainerPropsT = { disabled: boolean };
const Container = styled.div`
  .ql-editor {
    min-height: 5rem;

    &.ql-blank::before {
      color: #b3b3b3;
      font-weight: 400;
    }
  }
  ${(props: ContainerPropsT) => props.disabled && disabledContainerCss};
`;

const editorConfig = {
  defaultState: { ops: [] },
  theme: 'snow',
  formats: ['header', 'bold', 'italic', 'list', 'bullet', 'indent', 'link'],
  modules: {
    toolbar: [
      [
        { header: '1' },
        { header: '2' },
        'bold',
        'italic',
        'link',
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ]
    ]
  }
};
const editorStyle = { minHeight: '3rem' };

type OwnPropsT = {
  onChange: (value: any) => void,
  value: ?Object,
  meta: RichTextMeta,
  disabled: boolean,
  isTouchDevice: boolean,
  autoFocus?: boolean
} & TranslatableT;

type PropsT = OwnPropsT & {
  reactQuill: any
};

type StateT = {
  editorState: Object | null
};

class RichTextEditor extends Component<PropsT, StateT> {
  editor: any;
  modules: any;

  constructor(props: PropsT) {
    super(props);
    this.state = {
      editorState: this.getEditorStateFromProps(props)
    };
  }

  componentDidMount() {
    const { autoFocus = true } = this.props;
    if (autoFocus) {
      this.editor.focus();
    }
  }

  componentDidUpdate(prevProps: PropsT) {
    if (
      !prevProps.value &&
      this.props.value &&
      this.state.editorState === editorConfig.defaultState
    ) {
      this.setState({ editorState: this.getEditorStateFromProps(this.props) });
    }
  }

  getEditorStateFromProps = ({ value }: PropsT) => value || editorConfig.defaultState;

  onEditorChange = (value, delta, source, editor) => {
    const editorState = editor.getContents();
    if (!isEqual(this.state.editorState, editorState)) {
      this.setState(
        {
          editorState
        },
        () => {
          this.props.onChange(editor.getLength() !== 1 ? editorState : null);
        }
      );
    }
  };

  render() {
    const { reactQuill: Editor, disabled = false, translate } = this.props;
    return (
      <Container disabled={disabled}>
        <Editor
          ref={editor => {
            this.editor = editor;
          }}
          value={this.state.editorState}
          onChange={this.onEditorChange}
          theme={editorConfig.theme}
          placeholder={translate('RICH_TEXT_EDITOR_PLACEHOLDER')}
          formats={editorConfig.formats}
          modules={editorConfig.modules}
          readOnly={disabled}
          style={editorStyle}
        />
      </Container>
    );
  }
}

const AsyncRichTextEditorProvider = (props: OwnPropsT) => (
  <DynamicImport load={() => import('react-quill')}>
    {ReactQuill => <RichTextEditor reactQuill={ReactQuill} {...props} />}
  </DynamicImport>
);

export default injectI18n(AsyncRichTextEditorProvider);
