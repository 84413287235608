// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import { toLower, trim } from 'lodash';
import { BasicInput, ErrorMessage } from '@hypercharge/hyper-react-base/lib/form';
import { email as validateEmail } from '@hypercharge/hyper-react-base/lib/form/validation';

const PropertyInput = styled(BasicInput)`
  padding-bottom: 0 !important;

  ${ErrorMessage} {
    background: rgba(255, 255, 255, 0.9);
    z-index: 10;
    position: absolute;
  }
`;

type PropsT = {
  onChange: (value: any) => void,
  value: any,
  disabled: boolean,
  onKeyDown: (e: KeyboardEvent) => void,
  autoFocus?: boolean,
  editingInline?: boolean
};

type StateT = {
  initialValue: string | null,
  temporaryValue: string | null
};

class EmailEditor extends Component<PropsT, StateT> {
  state = {
    initialValue: this.props.value,
    temporaryValue: this.props.value
  };

  onChange = (e: any) => {
    const newValue = e.target.value ? trim(toLower(e.target.value)) : null;
    const validationError = validateEmail(newValue);
    this.props.onChange(
      !validationError || !this.props.editingInline ? newValue : this.state.initialValue
    );
    this.setState({ temporaryValue: newValue });
  };

  render() {
    const { disabled, onKeyDown, autoFocus = true, editingInline } = this.props;
    const { temporaryValue } = this.state;
    return (
      <PropertyInput
        input={{
          value: !temporaryValue ? '' : temporaryValue
        }}
        autoFocus={autoFocus}
        onChange={this.onChange}
        disabled={disabled}
        onKeyDown={onKeyDown}
        meta={
          editingInline
            ? {
                touched: true,
                error: validateEmail(temporaryValue)
              }
            : undefined
        }
      />
    );
  }
}

export default EmailEditor;
