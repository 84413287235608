// @flow

import { reducer as form } from 'redux-form';
import { i18nReducerFactory } from '@hypercharge/hyper-react-base/lib/i18n';
import { reducer as notifications } from '@hypercharge/hyper-react-base/lib/notifications';
import { browserReducer as browser } from '@hypercharge/hyper-react-base/lib/browser';
import { authReducer as auth } from '../../auth';
import { meReducer as me } from '../../me';
import { reducer as cms } from '../../cms';

import type { StateT as I18nStateT } from '@hypercharge/hyper-react-base/lib/i18n/i18nReducerFactory';
import type { StateT as NotificationsStateT } from '@hypercharge/hyper-react-base/lib/notifications';
import type { StateT as BrowserStateT } from '@hypercharge/hyper-react-base/lib/browser';
import type { StateT as AuthStateT } from '../../auth';
import type { StateT as MeStateT } from '../../me';
import type { StateT as CmsStateT } from '../../cms';

export type GlobalStateT = {
  i18n: I18nStateT,
  form: any,
  notifications: NotificationsStateT,
  browser: BrowserStateT,
  auth: AuthStateT,
  me: MeStateT,
  cms: CmsStateT
};

const reducers: GlobalStateT = {
  i18n: i18nReducerFactory(),
  form,
  notifications,
  browser,
  auth,
  me,
  cms
};

export default reducers;
