// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toUpper } from 'lodash';
import { injectI18n, getCountryInfo } from '@hypercharge/hyper-react-base/lib/i18n';
import DynamicImport from '@hypercharge/hyper-react-base/lib/common/dynamic-import';
import { getDisplayTenantStatus } from '../../../../../auth';
import 'react-phone-number-input/style.css';

import type { ComponentType } from 'react';
import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { GlobalStateT } from '../../../../../common/reducers/reducers.js';

type OwnPropsT = {
  onChange: (value: any) => void,
  value: any,
  disabled: boolean,
  onKeyDown: (e: KeyboardEvent) => void,
  autoFocus?: boolean,
  className?: string,
  editingInline?: boolean
};

type ConnectedStatePropsT = {
  defaultCountry: string,
  isPending: boolean
};

type DynamicImportPropsT = {
  phoneInputComponent: ComponentType<any>,
  smartInputComponent: ComponentType<any>,
  isValidPhoneNumber: (number: string) => boolean
};

type PropsT = OwnPropsT & TranslatableT & ConnectedStatePropsT & DynamicImportPropsT;

type StateT = {
  temporaryValue?: string,
  initialValue?: string,
  invalid: boolean
};

class PhoneNumberEditor extends Component<PropsT, StateT> {
  constructor(props) {
    super(props);
    // this is not correct as we are translating default language
    // to a country code need to look into fixing this
    const countryInfo = getCountryInfo(toUpper(this.props.defaultCountry));
    const defaultPrefix = (countryInfo && countryInfo.prefix) || '';

    this.state = {
      temporaryValue: this.props.value || defaultPrefix,
      initialValue: this.props.value || defaultPrefix,
      invalid: false
    };
  }

  onChange = (value: string) => {
    const valid = !this.props.editingInline || this.props.isValidPhoneNumber(value || '');
    this.props.onChange(valid ? value : this.state.initialValue);
    this.setState({ temporaryValue: value, invalid: !valid });
  };

  render() {
    const {
      phoneInputComponent,
      smartInputComponent,
      disabled,
      onKeyDown,
      autoFocus = true,
      className,
      editingInline,
      translate
    } = this.props;
    const { temporaryValue, invalid } = this.state;
    const PhoneInput = phoneInputComponent;
    return (
      <div className={`${className || ''} ${disabled ? 'disabled' : ''}`}>
        <PhoneInput
          displayInitialValueAsLocalNumber
          autoFocus={autoFocus}
          inputComponent={smartInputComponent}
          value={temporaryValue || ''}
          onChange={this.onChange}
          disabled={disabled}
          onKeyDown={onKeyDown}
          error={
            invalid && editingInline ? translate('VALIDATIONS__INVALID_PHONE_NUMBER') : undefined
          }
        />
      </div>
    );
  }
}

function mapStateToProps(s: GlobalStateT): ConnectedStatePropsT {
  const status = getDisplayTenantStatus(s);
  return {
    defaultCountry: 'BE',
    isPending: status.isPending
  };
}

const TranslatableEditor = compose(
  injectI18n,
  connect(mapStateToProps)
)(PhoneNumberEditor);

const StyledTranslatableEditor = styled(TranslatableEditor)`
  .react-phone-number-input__error {
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    z-index: 10;
    margin: 0;
    color: ${props => props.theme.invalidColor};
  }

  input {
    height: 33px;
    border-bottom: 1px solid #ccc;

    &:focus {
      border-bottom: 1px solid ${props => props.theme.mainColor};
    }

    &.react-phone-number-input__input--invalid,
    &.react-phone-number-input__input--invalid:focus {
      border-bottom: 1px solid ${props => props.theme.invalidColor};
    }
  }
  .react-phone-number-input__input--disabled {
    background-color: #f5f5f5;
  }

  &.disabled .react-phone-number-input__country {
    background: #f5f5f5;
  }

  .react-phone-number-input__country {
    border-bottom: 1px solid #ccc;
    margin-right: 0;
    padding-right: 0.5rem;
    background: ${props => (props.disabled ? '#f5f5f5' : null)};
  }

  .react-phone-number-input--focus .react-phone-number-input__country {
    border-bottom: 1px solid ${props => props.theme.mainColor};
  }

  .react-phone-number-input--invalid,
  .react-phone-number-input--invalid.react-phone-number-input--focus {
    .react-phone-number-input__country {
      border-bottom: 1px solid ${props => props.theme.invalidColor};
    }
  }

  .react-phone-number-input__country-select:focus + .react-phone-number-input__country-select-arrow,
  .react-phone-number-input__country.rrui__select--focus .rrui__select__arrow {
    color: ${props => props.theme.mainColor};
  }
`;

const EditorWrapper = (props: PropsT) => (
  <DynamicImport
    load={() =>
      Promise.all([
        import('react-phone-number-input/min'),
        import('react-phone-number-input/smart-input')
      ])
    }
    inline
    loadAllExports={true}
  >
    {([component, smartInputComponent]) => {
      return (
        <StyledTranslatableEditor
          {...props}
          phoneInputComponent={component.default}
          smartInputComponent={smartInputComponent.default}
          isValidPhoneNumber={component.isValidPhoneNumber}
        />
      );
    }}
  </DynamicImport>
);

export default EditorWrapper;
