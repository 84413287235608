// @flow

import CheckboxDisplay from "./display/CheckboxDisplay.js";
import DateDisplay from "./display/DateDisplay.js";
import EmailDisplay from "./display/EmailDisplay.js";
import EntityDisplay from "./display/EntityDisplay.js";
import LinkDisplay from "./display/LinkDisplay.js";
import NumberDisplay from "./display/NumberDisplay.js";
import PhoneNumberDisplay from "./display/PhoneNumberDisplay.js";
import PlainTextDisplay from "./display/PlainTextDisplay.js";
import RichTextDisplay from "./display/RichTextDisplay.js";
import CheckboxEditor from "./editors/CheckboxEditor.js";
import DateEditor from "./editors/DateEditor.js";
import EmailEditor from "./editors/EmailEditor.js";
import EntityEditor from "./editors/EntityEditor.js";
import LinkEditor from "./editors/LinkEditor.js";
import MultiTextEditor from "./editors/MultiTextEditor.js";
import NumberEditor from "./editors/NumberEditor.js";
import PhoneNumberEditor from "./editors/PhoneNumberEditor.js";
import PlainTextEditor from "./editors/PlainTextEditor.js";
import RichTextEditor from "./editors/RichTextEditor.js";

import type { Item } from "../../types.js";

const editorComponents = {
  text: PlainTextEditor,
  richtext: RichTextEditor,
  multitext: MultiTextEditor,
  number: NumberEditor,
  date: DateEditor,
  entity: EntityEditor,
  link: LinkEditor,
  checkbox: CheckboxEditor,
  phoneNumber: PhoneNumberEditor,
  email: EmailEditor
};

const displayComponents = {
  text: PlainTextDisplay,
  richtext: RichTextDisplay,
  multitext: PlainTextDisplay,
  number: NumberDisplay,
  date: DateDisplay,
  entity: EntityDisplay,
  link: LinkDisplay,
  checkbox: CheckboxDisplay,
  phoneNumber: PhoneNumberDisplay,
  email: EmailDisplay
};

export const getEditorComponentForType = (type: string) =>
  editorComponents[type];

export const getDisplayComponentForType = (type: string) =>
  displayComponents[type];

export const getItemRepresentation = (item: Item) =>
  item.title || item.entityId;
