// @flow

import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { parse, stringify } from 'query-string';
import { pick } from 'lodash';
import { AUTH_PATH, AUTH_EMAIL_PARAM } from './constants.js';
import { getIsUiAuthenticated, getAuthJwt } from './selectors.js';

import type { GlobalStateT } from '../common/reducers/reducers.js';

type OwnPropsT = {
  location?: any
};

const locationHelper = locationHelperBuilder({});

const getRedirectPath = (location: any) => {
  if (location && location.search) {
    const search = stringify(pick(parse(location.search), [AUTH_EMAIL_PARAM]));
    return `${AUTH_PATH}?${search}`;
  }
  return AUTH_PATH;
};

export const redirectIfAuthenticated = connectedRouterRedirect({
  // The user is redirected if this returns false
  authenticatedSelector: (s: GlobalStateT) => !getIsUiAuthenticated(s),
  redirectPath: (s: GlobalStateT, props: OwnPropsT) => {
    if (!getAuthJwt(s)) {
      // this output should never be used since the redirectPath gets used after being authenticated
      return getRedirectPath(props.location);
    }
    return locationHelper.getRedirectQueryParam(props) || '/';
  },
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  wrapperDisplayName: 'redirectIfAuthenticated'
});

export const redirectIfNotAuthenticated = connectedRouterRedirect({
  // The user is redirected if this returns false
  authenticatedSelector: (s: GlobalStateT) => getIsUiAuthenticated(s),
  redirectPath: (s: GlobalStateT, ownProps: OwnPropsT) => getRedirectPath(ownProps.location),
  wrapperDisplayName: 'redirectIfNotAuthenticated'
});
