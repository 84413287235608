// @flow

import React from "react";
import FaExternalLink from "react-icons/lib/fa/external-link";
import styled from "styled-components";

const ScLink = styled.a`
  font-weight: inherit !important;
  border: 0 !important;
  transition: color 200ms ease;
  word-break: break-word;

  &:hover {
    color: ${props => props.theme.mainColorVibrant};
  }
`;
const ScExternalLinkIcon = styled(FaExternalLink)`
  margin-left: 0.3rem;
`;

type PropsT = {
  value: any
};

const ensureWebsiteProtocol = (url: string) =>
  url.match(/(^https?:\/\/)|(^\/.+)/g) ? url : `//${url}`;

const isRelativeUrl = (url: string) => !!url.match(/^\/.+/g);

const LinkDisplay = ({ value }: PropsT) => {
  if (value && value.link) {
    const isExternalLink = !isRelativeUrl(value.link);
    return (
      <ScLink
        href={ensureWebsiteProtocol(value.link)}
        title={value.link}
        onClick={e => e.stopPropagation()}
        {...(isExternalLink ? { target: "_blank" } : {})}
      >
        {value.text || value.link}
        {isExternalLink && <ScExternalLinkIcon />}
      </ScLink>
    );
  } else if (value && value.text) {
    return value.text;
  } else {
    return "\u00a0";
  }
};

export default LinkDisplay;
