// @flow

import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Router } from 'react-router';
import { get } from 'lodash';
import { I18nProvider } from '@hypercharge/hyper-react-base/lib/i18n';
import { cmsMessages } from '../../cms';
import { meMessages } from '../../me';
import { DisplayTenantLoader, authMessages } from '../../auth';
import { getI18nState } from '../reducers/rootSelector.js';
import { theme } from '../utils/styledComponentsUtils.js';
import App from './App.js';

import type { Persistor } from 'redux-persist/lib/types';
import type { MessagesByLanguageT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { DisplayTenant } from '../../auth';

const extendedMessages: MessagesByLanguageT[] = [meMessages, cmsMessages, authMessages];

type PropsT = {
  store: Object,
  persistor: Persistor,
  history: Object
};

const renderI18nApp = ({ displayTenant }: { displayTenant: ?DisplayTenant }) => (
  <I18nProvider
    defaultLanguage={get(displayTenant, 'defaultLanguage', 'en')}
    availableLanguages={get(displayTenant, 'availableLanguages', ['en'])}
    getI18nState={getI18nState}
    extendedMessages={extendedMessages}
  >
    <App />
  </I18nProvider>
);

const Root = ({ store, persistor, history }: PropsT) => (
  <PersistGate persistor={persistor}>
    <ReduxProvider store={store}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <DisplayTenantLoader render={renderI18nApp} />
        </ThemeProvider>
      </Router>
    </ReduxProvider>
  </PersistGate>
);

export default Root;
