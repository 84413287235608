// @flow

import React from 'react';
import { Field } from 'redux-form';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';

import type { ComponentType } from 'react';
import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';

type PropsT = {
  autoFocus?: boolean,
  disabled?: boolean
} & TranslatableT;

const EmailFormField: ComponentType<PropsT> = ({ translate, autoFocus, disabled }: PropsT) => (
  <div>
    <Field
      name="email"
      label={translate('AUTH_EMAIL')}
      // $FlowFixMe
      component={BasicInput}
      type="text"
      placeholder="john@company.com"
      autoFocus={autoFocus}
      disabled={disabled}
    />
  </div>
);
// $FlowFixMe
export default injectI18n(EmailFormField);
