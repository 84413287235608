// @flow

import { combineReducers } from 'redux';
import { find } from 'lodash';
import { createReducer } from '@hypercharge/hyper-react-base/lib/utils';

import type {
  Item,
  FetchItemsByIdT,
  FetchItemsByIdSuccessT,
  FetchItemsByIdFailT,
  SearchItemsSuccessT
} from './types';

// ////////////////////
// Item (just values)
// ////////////////////

type ItemsByIdStateT = { [entityId: string]: Item };

const itemsById = createReducer(({}: ItemsByIdStateT), {
  CMS__FETCH_ITEMS_BY_ID_SUCCESS: (s: ItemsByIdStateT, a: FetchItemsByIdSuccessT) => {
    a.payload.results.forEach(item => {
      s[item.entityId] = item;
    });
  },
  CMS__SEARCH_ITEMS_SUCCESS: (s: ItemsByIdStateT, a: SearchItemsSuccessT) => {
    a.payload.results.forEach(item => {
      s[item.entityId] = item;
    });
  }
});

type StatusStateT = {
  [entityId: string]: {
    lastFetch?: Date,
    pending: boolean,
    failed: boolean
  }
};

const itemsStatus = createReducer(({}: StatusStateT), {
  CMS__FETCH_ITEMS_BY_ID: (s: StatusStateT, a: FetchItemsByIdT) => {
    const { ids } = a.meta.http.actionContext;
    ids.forEach(id => {
      const status = s[id] || {};
      status.pending = true;
      status.failed = false;
      s[id] = status;
    });
  },
  CMS__FETCH_ITEMS_BY_ID_SUCCESS: (s: StatusStateT, a: FetchItemsByIdSuccessT) => {
    const { ids } = a.meta.actionContext;
    ids.forEach(id => {
      const status = s[id] || {};
      status.pending = false;
      status.failed = !find(a.payload.results, { entityId: id });
      status.lastFetch = new Date();
      s[id] = status;
    });
  },
  CMS__FETCH_ITEMS_BY_ID_FAIL: (s: StatusStateT, a: FetchItemsByIdFailT) => {
    const { ids } = a.meta.actionContext;
    ids.forEach(id => {
      const status = s[id] || {};
      status.pending = false;
      status.failed = true;
      s[id] = status;
    });
  }
});

export type ItemsStateT = {
  byId: ItemsByIdStateT,
  status: StatusStateT
};

const items = combineReducers({
  byId: itemsById,
  status: itemsStatus
});

// ////////////////////
// Combined state
// ////////////////////

export type StateT = {
  items: ItemsStateT
};

export default combineReducers({ items });
