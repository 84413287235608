// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import type { ComponentType } from 'react';

type PropsT = {
  logoUrl: ?string
};

const LogoImage = styled.img`
  max-width: 90%;
  max-height: 6rem;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
`;

const Logo: ComponentType<PropsT> = ({ logoUrl }: PropsT) =>
  logoUrl ? (
    <Link to="/">
      <LogoImage src={logoUrl} alt="logo" />
    </Link>
  ) : null;

export default Logo;
