// @flow

import { injectGlobal } from 'styled-components';
import { theme } from './common/utils/styledComponentsUtils.js';
import { getFieldCss } from '@hypercharge/hyper-react-base/lib/form';
import 'normalize-css/normalize.css';
import 'hyperplan/dist/hyperplan.min.css';
import 'react-quill/dist/quill.snow.css';

const initMainStyles = () => {
  injectGlobal`
    html,
    body#hyper-body {
      min-height: 100%;
      font-family: ${theme.fontFamily};
      font-size: 16px;
      color: ${theme.textColor};
    }

    body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      overflow-x: hidden;
      position: static;
    }

    label {
      font-size: 0.9rem;
    }

    ${
      '' /* solves http://stackoverflow.com/questions/5804256/image-inside-div-has-extra-space-below-the-image */
    }
    div > img {
      display: block;
    }

    ${'' /* link elements */}
    .link,
    a:link,
    a:visited,
    a:hover {
      cursor: pointer;
      color: ${theme.mainColor};
      text-decoration: none;

      &.active {
        border-bottom: 2px solid ${theme.mainColor};
        font-weight: bold;
      }
    }

    .link:focus,
    a:focus {
      outline-style: none !important;
      text-decoration: none !important;
    }

    ${'' /* removes controls from inside number inputs */}
    input[type='number'] {
      -moz-appearance: textfield;
    }

    input[type=text]::-ms-clear {
      display: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    select::-ms-expand {
      display: none;
    }
  `;
};

const menuItemCss = `
  font-family: ${theme.fontFamily};
  font-size: 0.9rem;
  height: auto;
  min-height: 35px;
  line-height: 25px;
  padding: 5px 12px;
  transition: none;

  &[aria-haspopup="true"] {
    padding-right: 30px;
  }
`;

const menuTextColor = 'rgba(0, 0, 0, 0.65)';

const initAntStyles = () => {
  injectGlobal`
    .ant-switch.ant-switch-checked {
      background-color: #30e4a1;
    }
    .ant-dropdown-menu.ant-dropdown-menu {
      .ant-dropdown-menu-item-disabled {
        cursor: default;
      }
    }
    .ant-pagination.ant-pagination {
      .ant-pagination-disabled .ant-pagination-item-link {
        cursor: default;
      }
    }

    .ant-badge {
      .ant-badge-status-dot {
        width: 8px;
        height: 8px;
        left: 4px;
      }
    }

    .ant-collapse.ant-collapse  {
      padding-right: 1rem;
      border-bottom:${theme.thinBorder};
      border-radius: 0;

      .ant-collapse-item {
        border-bottom: 0;

        & > .ant-collapse-content > .ant-collapse-content-box {
          padding-top: 1rem;
        }
      }
      .arrow {
        top: 5px;
      }
    }

    .ant-input.ant-input, .ant-mention-wrapper .ant-mention-editor.ant-mention-editor {
      font-family: ${theme.fontFamily};
      ${getFieldCss(theme)};
      border-color: #ccc;
      min-height: 33px;
      box-shadow: none !important;
      &:focus {
        box-shadow: none !important;
      }
    }
    
    .ant-calendar-picker-container.ant-calendar-picker-container {
      font-family: ${theme.fontFamily};
    }

    .ant-upload.ant-upload.ant-upload-drag {
      background: #fff;
      transition: opacity 200ms ease;

      &.ant-upload-disabled {
        cursor: default;
        opacity: 0.7;
      }
    }
    .ant-select.ant-select {
      color: #333;
      font-weight: 500;
      font-family: ${theme.fontFamily};

      .ant-select-selection {
        min-height: 33px;
        border-color: #ccc;
        border-radius: ${theme.defaultRadius};
        box-shadow: none;
      }

      &.ant-select-focused .ant-select-selection.ant-select-selection {
        border-color: ${theme.mainColor};
        box-shadow: none;
      }

      .ant-select-dropdown--single.ant-select-dropdown--single {
        margin-top: -5px;
      }
    }
    .ant-input.ant-input {
      border-radius: 0;
    }
    .ant-calendar-picker.ant-calendar-picker {
      margin-bottom: 0.7rem;
    }
    
    .ant-menu-vertical, 
    .ant-menu-vertical-left , 
    .ant-menu-vertical-right , 
    .ant-menu-inline {
      .ant-menu-item {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
      .ant-menu-item:last-child {
        margin-top: 0 !important;
      }
    }

    body > div > div > .ant-menu-submenu .main-menu-item .down-arrow {
      display: none;
    }

    .ant-dropdown-menu, .ant-dropdown-menu-submenu {
      svg {
        width: 16px;
        height: 16px;
      }
      .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
        ${menuItemCss}
        > a {
          color: ${menuTextColor};
        }
        &:not([class$="-disabled"]) {
          color: ${menuTextColor};
          &:hover{
            background-color: ${theme.mainColorSoft};
            svg {
              color: ${theme.mainColor} !important; 
            }
          }
        }
      }
    }

    /* styling to make it uniform with the menu */
    .ant-dropdown-menu-submenu-arrow {

      -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      position: absolute;
      top: 50%;
      right: 16px;
      width: 10px;

      > i {
        display: none !important;
      }

      &::before, &::after {
        content: '';
        position: absolute;
        vertical-align: baseline;
        background: #fff;
        background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.65)), to(rgba(0, 0, 0, 0.65)));
        background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
        width: 6px;
        height: 1.5px;
        border-radius: 2px;
        -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &::before {
        -webkit-transform: rotate(45deg) translateY(-2px);
        -ms-transform: rotate(45deg) translateY(-2px);
        transform: rotate(45deg) translateY(-2px);
      }

      &::after {
        -webkit-transform: rotate(-45deg) translateY(2px);
        -ms-transform: rotate(-45deg) translateY(2px);
        transform: rotate(-45deg) translateY(2px);
      }
    }

    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item{
        ${menuItemCss}
        &:hover{
          background-color: ${theme.mainColorSoft};
        }

        &:not(:first-child)#NEW_CUSTOM_ENTITY {
          border-top: 1px solid #eaeaea;

          .ant-select-selected-icon {
            display: none;
          }
        }
      }
      .ant-select-dropdown-menu-item-active {
        background-color: ${theme.mainColorSoft};
      }
    }

    .ant-menu-submenu-popup {
      z-index: 1040 !important;
    }

    .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled, 
    .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-title-disabled {
      > * {
        color: rgba(0, 0, 0, 0.25);
        cursor: not-allowed;
      }
    }

    .public-DraftStyleDefault-block.public-DraftStyleDefault-block {
      margin: 0;
    }

    .ant-modal-content {
      max-height: 100vh;
    }
  
    .ant-modal-body {
      max-height: 70vh;
      overflow: auto;
    }
    
  `;
};

const init = () => {
  initMainStyles();
  initAntStyles();
};

export default init;
