// @flow

import React from 'react';
import styled from 'styled-components';
import { BasicInput } from '@hypercharge/hyper-react-base/lib/form';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';

import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { LinkMeta } from '../../../types.js';

const Container = styled.div`
  position: relative;
`;
const StyledInput = styled(BasicInput)`
  padding-bottom: 0 !important;
`;
const LinkInput = styled(StyledInput)`
  position: absolute;
  top: 33px;
  left: 0;
  right: 0;
  z-index: 1;
`;

type PropsT = {
  onChange: (value: any) => void,
  value: any,
  meta: LinkMeta,
  disabled: boolean,
  onKeyDown: (e: SyntheticEvent<*>) => void,
  autoFocus?: boolean
} & TranslatableT;

const LinkEditor = ({
  meta,
  value,
  onChange,
  disabled,
  onKeyDown,
  autoFocus = true,
  translate
}: PropsT) => {
  const { text, link } = value || { text: '', link: '' };
  const LinkInputComponent = autoFocus ? LinkInput : StyledInput;
  return (
    <Container>
      <StyledInput
        input={{ value: text }}
        onChange={e => onChange(e.target.value || link ? { text: e.target.value, link } : null)}
        disabled={disabled}
        placeholder={translate('PROPERTY_FIELD_TYPE_link_TEXT_PLACEHOLDER')}
        onKeyDown={onKeyDown}
      />
      <LinkInputComponent
        input={{ value: link }}
        autoFocus={autoFocus}
        onChange={e => onChange(e.target.value || text ? { text, link: e.target.value } : null)}
        disabled={disabled}
        placeholder={translate('PROPERTY_FIELD_TYPE_link_LINK_PLACEHOLDER')}
        onKeyDown={onKeyDown}
      />
    </Container>
  );
};

export default injectI18n(LinkEditor);
