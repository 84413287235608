const messages = {
  GDPR_PORTAL: 'Portail de RGPD',
  MORE_INFO: 'Autres demandes liées au RGPD?',
  CONTACT_US: 'Nous contacter',

  PRIVACY_POLICY: 'Déclaration de confidentialité',

  FAILED_ME_FETCH_MESSAGE:
    "Malheureusement, quelque chose s'est mal passé lors du chargement de vos informations.",
  ACCOUNT_EXPORT_DATA_TITLE: 'Exporter vos données',
  ACCOUNT_EXPORT_DATA_TEXT: 'Demander une exportation de toutes vos données personnelles.',
  ACCOUNT_EXPORT_DATA_BUTTON: 'Demander exportation de données',
  ACCOUNT_EXPORT_DATA_SUCCESS:
    'Vos données sont en cours d’exportation. Vous recevrez un email avec vos données quand le traitement est terminé.',
  ACCOUNT_EXPORT_DATA_ERROR: 'Echec exportation de vos données',
  ACCOUNT_DEACTIVATE_TITLE: 'Désactiver votre compte',
  ACCOUNT_DEACTIVATE_TEXT:
    'Désactiver votre compte, masquer le pour tout le personnel et éviter l’utilisation future pour toutes activités.',
  ACCOUNT_DEACTIVATE_BUTTON: 'Demander la désactivation du compte',
  ACCOUNT_DEACTIVATE_CONFIRM_TEXT: 'Continuer avec la désactivation du compte?',
  ACCOUNT_DEACTIVATE_SUCCESS:
    'Votre compte a été marqué pour désactivation. Vous serez contacté que le processus est terminé.',
  ACCOUNT_DEACTIVATE_ERROR: 'Echec de désactivation de votre compte',

  GDPR_NEEDS_ACTIVATION: "Malheureusement, le portail GDPR n'est pas encore active.",

  GDPR_NO_CHANGES: "Aucune modification n'a été apportée à vos données.",
  GDPR_CHANGES_WERE_REQUESTED:
    "Vos modifications ont été soumises avec succès et vous en serez informé une fois qu'elles auront été vérifié.",
  CORRECT_INVALID_FIELDS: "Corrigez les champs invalides s'il vous plaît"
};

export default messages;
