// @flow

import { connect } from 'react-redux';
import { get } from 'lodash';
import { getDisplayTenant } from '../../../auth';
import Logo from './Logo.js';

import type { GlobalStateT } from '../../reducers/reducers.js';

type ConnectedStatePropsT = {
  logoUrl: ?string
};

const mapStateToProps = (s: GlobalStateT): ConnectedStatePropsT => ({
  logoUrl: get(getDisplayTenant(s), 'logoUrl')
});

export default connect(mapStateToProps)(Logo);
