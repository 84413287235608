// @flow

import React from 'react';
import styled from 'styled-components';
import { media } from '@hypercharge/hyper-react-base/lib/styling';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';

import type { Node } from 'react';
import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';

type PropsT = {
  className?: string,
  children: Node
} & TranslatableT;

const Container = styled.div`
  padding-top: 7rem;
  padding-bottom: 2rem;

  ${media.sm} {
    padding-top: 8rem;
  }
`;
const Frame = styled.div`
  ${media.sm} {
    min-width: 25rem !important;
    max-width: 25rem !important;
  }
`;
export const FrameContainer = styled.div`
  background: #fff;
  border: 1px solid #d9e3ed;
  padding: 1rem;
  position: relative;
`;
export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  padding: 4rem 1rem 1rem;

  ${media.sm} {
    padding: 4rem 2rem 1rem;
  }
`;
export const Subtitle = styled.div`
  padding: 0.25rem 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word;

  ${media.sm} {
    padding: 0.25rem 2rem;
  }
`;
const Footer = styled.div`
  text-align: center;
  margin-top: 1.5rem;
  font-size: 0.8rem;
  color: #646464;
`;

const subject = "I can't log in to the GDPR portal";

const body = `Hi,I can’t login with my email: {please fill in login email}, at the GDPR portal with the URL: ${
  window.location.hostname
}`;

const FrameComponent = ({ className, children, translate, ...otherProps }: PropsT) => (
  <Container
    className={`cp-c-row cp-c-wrap cp-c-align-center-center ${className || ''}`}
    {...otherProps}
  >
    <Frame className="cp-i-90 cp-i-sm-none">{children}</Frame>
    <Footer className="cp-i-100">
      <a
        href={`mailto:support@hypercharge.io?subject=${subject}&body=${body}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {translate('CONTACT_SUPPORT')}
      </a>
    </Footer>
  </Container>
);

export default injectI18n(FrameComponent);
