// @flow

const messages = {
  AUTH_UNAVAILABLE:
    'Sfortunatamente il servizio non è disponibile al momento. Per favore riprova più tardi.',
  AUTH_SIGN_IN_NAV_LINK: 'Registrati',
  AUTH_LOG_OUT_NAV_LINK: 'Disconnettersi',
  AUTH_GET_STARTED: 'Iniziare',
  AUTH_GET_STARTED_BY_EMAIL_SUBTITLE: 'Inserisci la tua email per iscriverti o accedere.',
  AUTH_GET_STARTED_BY_PHONE_SUBTITLE: 'Inserisci il tuo cellulare per registrarti o accedere.',
  AUTH_CODE: 'Codice',
  AUTH_CODE_PLACEHOLDER: 'Inserisci il tuo codice…',
  AUTH_AN_AUTHENTICATION_CODE_WAS_SENT_TO: 'È stato inviato un codice di autenticazione a',
  AUTH_SEND_NEW_CODE: 'Invia nuovo codice',
  AUTH_A_NEW_CODE_WAS_SENT: 'Un nuovo codice è stato inviato!',
  AUTH_CHOOSE_ACCOUNT: 'Scegli profilo',
  AUTH_MULTIPLE_ACCOUNTS_ARE_LINKED_TO: 'Profili multipli sono collegati a',
  AUTH_SOMETHING_WENT_WRONG: 'Qualcosa è andato storto.',
  AUTH_REQUEST_CODE_ERROR_MESSAGE:
    'Si è verificato un problema durante la richiesta di un codice di accesso. Per favore riprova più tardi.',
  AUTH_EMAIL: 'Email'
};

export default messages;
