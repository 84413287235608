// @flow

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get } from 'lodash';
import { getDisplayTenant } from '../../auth';
import { getDisplayGdprConfig, getDisplayGdprConfigStatus } from '../../me/selectors';
import type { FetchStatusT } from '@hypercharge/hyper-react-base/lib/types';
import Header from './Header';
import type { GlobalStateT } from '../../common/reducers/reducers';
import { getTranslation } from '@hypercharge/hyper-react-base/lib/i18n';
import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
type OwnPropsT = {
  className?: string
};

type ConnectedStatePropsT = {
  tenantName: string,
  tenantWebsiteUrl: string,
  tenantLogoUrl: string,
  publicWebsiteUrlTitle: string,
  displayGdprConfigStatus: FetchStatusT
};

export type PropsT = OwnPropsT & ConnectedStatePropsT;

const HeaderContainer = ({
  className,
  tenantName,
  tenantWebsiteUrl,
  tenantLogoUrl,
  publicWebsiteUrlTitle,
  displayGdprConfigStatus
}: PropsT) => (
  <Header
    className={className}
    tenantName={tenantName}
    tenantWebsiteUrl={tenantWebsiteUrl}
    tenantLogoUrl={tenantLogoUrl}
    publicWebsiteUrlTitle={publicWebsiteUrlTitle}
    displayGdprConfigStatus={displayGdprConfigStatus}
  />
);

function mapStateToProps(state: GlobalStateT, { language }: TranslatableT): ConnectedStatePropsT {
  const displayGdprConfig = getDisplayGdprConfig(state);
  const displayTenant = getDisplayTenant(state);

  return {
    tenantName: get(displayTenant, 'name'),
    tenantWebsiteUrl: getTranslation(get(displayGdprConfig, 'publicWebsiteUrl'), language),
    tenantLogoUrl: get(displayTenant, 'logoUrl'),
    publicWebsiteUrlTitle: get(displayGdprConfig, 'publicWebsiteUrlTitle'),
    displayGdprConfigStatus: getDisplayGdprConfigStatus(state)
  };
}

export default compose(
  injectI18n,
  connect(mapStateToProps)
)(HeaderContainer);
