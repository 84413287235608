// @flow

const messages = {
  AUTH_UNAVAILABLE:
    'Helaas is de dienst onbeschikbaar op dit moment. Gelieve later opnieuw te proberen.',
  AUTH_SIGN_IN_NAV_LINK: 'Log in',
  AUTH_LOG_OUT_NAV_LINK: 'Log uit',
  AUTH_GET_STARTED: 'Ga van start',
  AUTH_GET_STARTED_BY_EMAIL_WITH_SIGNUP_SUBTITLE:
    'Vul uw e-mailadres in om te registreren of in te loggen.',
  AUTH_GET_STARTED_BY_EMAIL_SUBTITLE: 'Vul uw e-mailadres in om in te loggen.',
  AUTH_CODE: 'Toegangscode',
  AUTH_CODE_PLACEHOLDER: 'Typ uw code...',
  AUTH_AN_AUTHENTICATION_CODE_WAS_SENT_TO: 'Toegangscode verzonden naar',
  AUTH_SEND_NEW_CODE: 'Nieuwe code verzenden',
  AUTH_A_NEW_CODE_WAS_SENT: 'Nieuwe code verzonden!',
  AUTH_CHOOSE_ACCOUNT: 'Kies account',
  AUTH_MULTIPLE_ACCOUNTS_ARE_LINKED_TO: 'Meerdere accounts zijn gelinkt aan',
  AUTH_SOMETHING_WENT_WRONG: 'Er liep iets fout.',
  AUTH_REQUEST_CODE_ERROR_MESSAGE:
    'Er was een probleem met het opvragen van een login code. Probeer later opnieuw.',
  AUTH_EMAIL: 'E-mail'
};

export default messages;
