// @flow

import { SectionCard } from '@hypercharge/hyper-react-base/lib/common/section-card';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import React from 'react';
import FaExternalLink from 'react-icons/lib/fa/external-link';
import styled from 'styled-components';

import type { ComponentType } from 'react';
import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';

type PropsT = {
  className?: string,
  logoUrl: ?string,
  name: ?string,
  publicWebsiteUrl: ?string,
  publicWebsiteUrlTitle?: string
} & TranslatableT;

const Logo = styled.img`
  max-width: 150px;
  max-height: 125px;
  margin: auto;
`;
const ScName = styled.div`
  padding-top: ${props => (props.withPaddingTop ? '1rem' : null)};
  text-align: center;
`;
const ScLinkIcon = styled(FaExternalLink)`
  font-size: 0.8rem;
`;
const ScMoreInfo = styled.div`
  font-size: 0.8rem;
  color: ${props => props.theme.textColorLight};
  width: 100%;
  border-top: 1px solid rgb(232, 232, 232);
  padding: 1rem;
`;

const TenantInfo: ComponentType<PropsT> = ({
  className,
  logoUrl,
  name,
  publicWebsiteUrl,
  publicWebsiteUrlTitle,
  translate
}: PropsT) => (
  <SectionCard title={translate('GDPR_PORTAL')} withoutPadding withoutMargin className={className}>
    <div className="cp-c-column cp-c-align-center-center cp-c-padding-2 cp-c-sm-padding-2">
      {logoUrl && <Logo src={logoUrl} alt="logo" />}
      {(publicWebsiteUrlTitle || name) && (
        <ScName withPaddingTop={logoUrl}>
          {publicWebsiteUrl ? (
            <a href={publicWebsiteUrl} rel="noopener noreferrer" target="_blank">
              {publicWebsiteUrlTitle || name} <ScLinkIcon />
            </a>
          ) : (
            publicWebsiteUrlTitle || name
          )}
        </ScName>
      )}
    </div>
    <ScMoreInfo>
      <span>{translate('MORE_INFO')} </span>
      {publicWebsiteUrl ? (
        <a href={publicWebsiteUrl} rel="noopener noreferrer" target="_blank">
          {translate('CONTACT_US')} <ScLinkIcon />
        </a>
      ) : (
        `${translate('CONTACT_US')}.`
      )}
    </ScMoreInfo>
  </SectionCard>
);

export default injectI18n(TenantInfo);
