// @flow

import { error } from '@hypercharge/hyper-react-base/lib/notifications';

import type { DispatchT } from '@hypercharge/hyper-react-base/lib/types';
import type { TranslateT } from '@hypercharge/hyper-react-base/lib/i18n';

export const handleFailure = (
  dispatch: DispatchT,
  translate: TranslateT,
  customTranslationKey?: string
) => () =>
  dispatch(
    error({
      title: translate('COMMON__FAILURE'),
      message: translate(customTranslationKey || 'AUTH_SOMETHING_WENT_WRONG')
    })
  );
