// @flow

import React from 'react';
import DynamicImport from '@hypercharge/hyper-react-base/lib/common/dynamic-import';

type PropsT = {
  value: any
};

const PhoneNumberDisplay = ({ value }: PropsT) =>
  value ? (
    <DynamicImport load={() => import('react-phone-number-input/min')} loadAllExports={true} inline>
      {component =>
        ('string' === typeof value && component.formatPhoneNumberIntl(value)) || '\u00a0'
      }
    </DynamicImport>
  ) : (
    '\u00a0'
  );

export default PhoneNumberDisplay;
