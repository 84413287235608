// @flow

import React from 'react';
import styled from 'styled-components';

type PropsT = {
  currentYear: number,
  publicWebsiteUrlTitle: string
};

const Container = styled.div`
  background-color: ${props => props.theme.textColorLight};
  line-height: 1.3rem;
  font-size: 0.8rem;
  color: #fff;

  @media print {
    display: none;
  }
`;
const Copyright = styled.div`
  text-align: center;
  background: #737373;
  padding: 0.25rem 0;
`;

const DesktopFooter = ({ currentYear, publicWebsiteUrlTitle }: PropsT) =>
  publicWebsiteUrlTitle ? (
    <Container>
      <Copyright>
        &copy; {currentYear} {publicWebsiteUrlTitle}
      </Copyright>
    </Container>
  ) : null;

export default DesktopFooter;
