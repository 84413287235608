// @flow

import styled from 'styled-components';
import { media } from '@hypercharge/hyper-react-base/lib/styling';
import { Button } from '@hypercharge/hyper-react-base/lib/common/button';

export const Email = styled.span`
  font-weight: 600;
`;
export const Content = styled.div`
  padding: 1rem;

  ${media.sm} {
    padding: 2rem;
  }
`;
export const SubmitButton = styled(Button)`
  width: 100%;
`;
export const CenteredText = styled.div`
  text-align: center;
`;
