// @flow

import React from 'react';
import styled from 'styled-components';
import MenuContainer from './MenuContainer.js';
import type { FetchStatusT } from '@hypercharge/hyper-react-base/lib/types';

type PropsT = {
  className?: string,
  tenantName?: string,
  tenantWebsiteUrl?: string,
  tenantLogoUrl?: string,
  publicWebsiteUrlTitle?: string,
  displayGdprConfigStatus: FetchStatusT
};

const Container = styled.div`
  z-index: 1000;
  background-color: #fff;
  padding: 0 1rem;
  min-height: 3rem;

  @media print {
    display: none;
  }
`;

const OptionalLink = ({ children, ...rest }) =>
  rest.href ? (
    <a {...rest} className="cp-c-row cp-c-align-start-center">
      {children}
    </a>
  ) : (
    children
  );

const Logo = styled.img`
  height: 30px;
  margin-right: 0.5rem;
`;

const Header = ({
  className,
  tenantName,
  tenantWebsiteUrl,
  tenantLogoUrl,
  publicWebsiteUrlTitle,
  displayGdprConfigStatus
}: PropsT) => (
  <Container className={`cp-c-row cp-c-align-spacebetween-center ${className || ''}`}>
    <div className="cp-c-row cp-c-align-start-center">
      <OptionalLink href={tenantWebsiteUrl} rel="noopener noreferrer" target="_blank">
        {tenantLogoUrl && <Logo src={tenantLogoUrl} alt="Logo" />}
        {!displayGdprConfigStatus.isPending &&
          (publicWebsiteUrlTitle || tenantName) && (
            <span>{publicWebsiteUrlTitle || tenantName}</span>
          )}
      </OptionalLink>
    </div>
    <MenuContainer className="cp-i-none" />
  </Container>
);

export default Header;
