// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { FrameContainer, Title, Subtitle } from '../../../common/components/frame';
import Logo from '../../../common/components/logo';
import { SubmitButton } from '../common/components.js';
import EmailFormField from '../common/EmailFormField.js';

import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { DisplayTenant } from '../../types.js';

type PropsT = {
  className?: string,
  displayTenant: DisplayTenant,
  // injected by redux-form
  submitting: boolean,
  handleSubmit: (data: { [field: string]: string }) => ?Promise<any>
} & TranslatableT;

const GetStartedByEmailForm = ({
  className,
  displayTenant,
  submitting,
  handleSubmit,
  translate
}: PropsT) => (
  <FrameContainer className={className}>
    <Logo />
    <Helmet>
      <title>{`${translate('AUTH_SIGN_IN_NAV_LINK')} - ${displayTenant.name}`}</title>
    </Helmet>
    <Title>{translate('AUTH_GET_STARTED')}</Title>
    <Subtitle>{translate('AUTH_GET_STARTED_BY_EMAIL_SUBTITLE')}</Subtitle>
    <form onSubmit={handleSubmit} noValidate>
      <div className="cp-c-padding-2 cp-c-sm-padding-h-3">
        <EmailFormField disabled={submitting} />
        <div>
          <SubmitButton type="submit" loading={submitting}>
            {translate('COMMON__CONTINUE')}
          </SubmitButton>
        </div>
      </div>
    </form>
  </FrameContainer>
);

export default injectI18n(GetStartedByEmailForm);
