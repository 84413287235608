// @flow

import type { Item } from '../cms';
import type {
  FetchDisplayGdprConfigT,
  FetchMeT,
  ExportDataT,
  DeactivateAccountT,
  SubmitChangesT
} from './types.js';

const gdprPath = '/v2-gdpr';

export const fetchDisplayGdprConfig = (): FetchDisplayGdprConfigT => ({
  type: 'ME__FETCH_CONFIG',
  meta: {
    http: {
      path: `${gdprPath}/config`,
      method: 'GET'
    },
    auth: true
  }
});

export const fetchMe = (): FetchMeT => ({
  type: 'ME__FETCH_ME',
  meta: {
    http: {
      path: `${gdprPath}/me`,
      method: 'GET'
    },
    auth: true // TODO: check if this works without because that would be unsafe
  }
});

export const exportData = (): ExportDataT => ({
  type: 'ME__EXPORT_DATA',
  payload: {},
  meta: {
    http: {
      path: `${gdprPath}/actions/export`,
      method: 'POST',
      allowPromiseReject: true
    },
    auth: true
  }
});

export const deactivateAccount = (): DeactivateAccountT => ({
  type: 'ME__DEACTIVATE_ACCOUNT',
  payload: {},
  meta: {
    http: {
      path: `${gdprPath}/actions/deactivate`,
      method: 'POST',
      allowPromiseReject: true
    },
    auth: true
  }
});

export const submitChanges = (updatedValues: Item, languageCode: string): SubmitChangesT => ({
  type: 'ME__SUBMIT_CHANGES',
  payload: updatedValues,
  meta: {
    http: {
      path: `${gdprPath}/actions/change`,
      method: 'POST',
      params: { languageCode },
      allowPromiseReject: true
    },
    auth: true
  }
});
