// @flow

import React, { Component } from 'react';
import { Radio } from 'antd';
import styled from 'styled-components';
import { get } from 'lodash';
import { injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import MdClear from 'react-icons/lib/md/clear';

import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { CheckboxMeta } from '../../../types.js';

type PropsT = {
  onChange: (value: boolean | null) => void,
  value: boolean | null,
  meta: CheckboxMeta,
  disabled: boolean,
  autoFocus?: boolean,
  allowClear: boolean,
  onKeyDown: (e: SyntheticEvent<*>) => void
} & TranslatableT;

type StateT = {
  focused: boolean
};

const RadioButton = Radio.Button;

const StyledRadioGroup = styled(Radio.Group)`
  padding: ${props => (props.autoFocus ? '4px 0' : '5px 0 4px')} !important;
  border-bottom: ${props => (props.autoFocus ? `1px solid ${props.theme.mainColor}` : 'none')};
  width: 100%;
`;
const ClearRadioButton = styled(RadioButton)`
  padding-right: 10px !important;
  border: 0 !important;

  &::before {
    background-color: #fff !important;
  }

  &:: after {
    border: 0 !important;
  }
`;
const ClearIcon = styled(MdClear)`
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 50px;
  font-size: 0.7rem;
  transition: background 0.3s ease;

  :hover {
    background: rgba(0, 0, 0, 0.45);
  }
`;

class CheckboxEditor extends Component<PropsT, StateT> {
  state: StateT = { focused: false };

  valueToKey = (): ?('true' | 'false') => {
    switch (this.props.value) {
      case true:
        return 'true';
      case false:
        return 'false';
      default:
        return undefined;
    }
  };

  keyToValue = (value: ?('true' | 'false')): boolean | null => {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return null;
    }
  };

  render() {
    const { onChange, autoFocus = true, allowClear, disabled, value, meta, translate } = this.props;
    return (
      <StyledRadioGroup
        autoFocus={autoFocus}
        size="small"
        buttonStyle="solid"
        value={this.valueToKey()}
        onChange={e => onChange(this.keyToValue(e.target.value))}
        disabled={disabled}
      >
        <div className="cp-c-row cp-c-align-spacebetween-center">
          <div>
            <RadioButton value="true">{translate('YES')}</RadioButton>
            <RadioButton value="false">{translate('NO')}</RadioButton>
          </div>
          {allowClear &&
            !disabled &&
            !get(meta, 'validations.required', false) &&
            value != null && (
              <ClearRadioButton value="">
                <ClearIcon />
              </ClearRadioButton>
            )}
        </div>
      </StyledRadioGroup>
    );
  }
}

export default injectI18n(CheckboxEditor);
