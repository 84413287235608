// @flow

import {
  FormFooterContainer,
  InputContainer,
  LabelText
} from '@hypercharge/hyper-react-base/lib/form';
import { getTranslation, injectI18n } from '@hypercharge/hyper-react-base/lib/i18n';
import { Alert, notification } from 'antd';
import produce from 'immer';
import { get, isArray, isEmpty, isEqual, reduce } from 'lodash';
import React, { Component, Fragment } from 'react';
import IoAndroidAlert from 'react-icons/lib/io/android-alert';
import styled from 'styled-components';
import { getDisplayComponentForType, getEditorComponentForType } from '../../../cms';

import type { TranslatableT } from '@hypercharge/hyper-react-base/lib/i18n';
import type { LabelByLanguageT } from '@hypercharge/hyper-react-base/lib/types';
import type { DisplaySection } from '../../../cms';
import type { DisplayGdprConfig } from '@hypercharge/portal-utils';

type PropsT = {
  displayGdprConfig: ?DisplayGdprConfig,
  data: DisplaySection[],
  submitChanges: (item: Object) => Promise<any>
} & TranslatableT;

type StateT = {
  formValues: Object,
  submitting: boolean,
  submitted: boolean,
  errors: { [propertyId: string]: string }
};

class MyContactForm extends Component<PropsT, StateT> {
  isTouchDevice: boolean;

  constructor(props: PropsT) {
    super(props);
    this.state = this.getInitialState(props);
    this.isTouchDevice = 'ontouchstart' in document.documentElement;
  }

  getInitialState = ({ data }: PropsT): StateT => ({
    formValues: reduce(
      data,
      (prevFormValues, { values }) =>
        produce(prevFormValues, draft => {
          values.forEach(({ propertyId, value }) => {
            draft[propertyId] = value;
          });
        }),
      {}
    ),
    submitting: false,
    submitted: false,
    errors: {}
  });

  resetForm = () => this.setState(this.getInitialState(this.props));

  handleSubmit = e => {
    e && e.preventDefault();
    this.setState({ submitting: true, submitted: false, errors: {} });

    const initialState = this.getInitialState(this.props);
    if (isEqual(this.state.formValues, initialState.formValues)) {
      notification.open({
        message: this.props.translate('GDPR_NO_CHANGES')
      });
      this.setState({ submitting: false });
    } else {
      this.props
        .submitChanges(this.state.formValues)
        .then(
          () => {
            this.setState({ submitting: false, submitted: true });
          },
          e => {
            this.setState({
              submitting: false,
              submitted: true,
              errors: e.response.data
            });
          }
        )
        .finally(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    }
  };

  getSectionTitleJsx = (sectionId: string, titles: LabelByLanguageT, isFirstSection: boolean) => {
    if (sectionId === 'system') {
      return null;
    }
    const title = getTranslation(titles, this.props.language);
    if (!title && isFirstSection) {
      return null;
    }
    const subtitle = getTranslation(
      get(this.props, ['displayGdprConfig', 'sectionSubtitles', sectionId]),
      this.props.language
    );
    return (
      <FormSectionTitleContainer>
        <FormSectionTitle>{title}</FormSectionTitle>
        {subtitle && <FormSectionSubtitle>{subtitle}</FormSectionSubtitle>}
      </FormSectionTitleContainer>
    );
  };

  getValueDisplayJsx = (type: string, value: any, meta: any) => {
    if (value === null || value === '' || (isArray(value) && value.length === 0)) {
      return <EmptyValue>{`(${this.props.translate('EMPTY_VALUE')})`}</EmptyValue>;
    }
    const DisplayComp = getDisplayComponentForType(type);
    if (!DisplayComp) {
      return null;
    }
    return <DisplayComp value={value} meta={meta} />;
  };

  render() {
    const { data, translate, language } = this.props;
    const initialState = this.getInitialState(this.props);
    return (
      <Fragment>
        <FormContainer>
          {this.state.submitted &&
            (isEmpty(this.state.errors) ? (
              <Alert message={translate('GDPR_CHANGES_WERE_REQUESTED')} type="info" />
            ) : (
              <Alert message={translate('CORRECT_INVALID_FIELDS')} type="error" closable />
            ))}
          {this.state.submitted && isEmpty(this.state.errors)
            ? data.map(({ sectionId, titles, values }, sectionIndex) => (
                <Fragment key={sectionId}>
                  {this.getSectionTitleJsx(sectionId, titles, sectionIndex === 0)}
                  {values.map(({ propertyId, labels, type, meta }, propertyIndex) => {
                    const initialValue = get(initialState, ['formValues', propertyId]);
                    const submittedValue = get(this.state, ['formValues', propertyId]);
                    return (
                      <InputContainer key={propertyId}>
                        <LabelText>{getTranslation(labels, language)}</LabelText>
                        <ScInitialValue changed={initialValue !== submittedValue}>
                          {this.getValueDisplayJsx(type, initialValue, meta)}
                        </ScInitialValue>
                        {initialValue !== submittedValue && (
                          <ScNewValue>
                            {this.getValueDisplayJsx(type, submittedValue, meta)}
                          </ScNewValue>
                        )}
                      </InputContainer>
                    );
                  })}
                </Fragment>
              ))
            : data.map(({ sectionId, titles, values }, sectionIndex) => (
                <Fragment key={sectionId}>
                  {this.getSectionTitleJsx(sectionId, titles, sectionIndex === 0)}
                  {values
                    .filter(({ meta = {} }) => !meta.hidden)
                    .map(({ propertyId, labels, type, meta, canEdit }, propertyIndex) => {
                      const EditorComponent = getEditorComponentForType(type);
                      if (!EditorComponent) {
                        return null;
                      }
                      const errorMessage = this.state.errors[propertyId];
                      const isRequiredProperty = get(meta, 'validations.required');
                      return (
                        <InputContainer key={propertyId}>
                          <LabelText>
                            {getTranslation(labels, language)}
                            {errorMessage && !this.state.submitting && <StyledAlert />}
                            {isRequiredProperty && (
                              <RequiredInfo> ({translate('REQUIRED')})</RequiredInfo>
                            )}
                          </LabelText>
                          <EditorComponent
                            onChange={value =>
                              this.setState(
                                produce(this.state, draft => {
                                  draft.formValues[propertyId] = value;
                                })
                              )
                            }
                            value={get(this.state, ['formValues', propertyId])}
                            meta={meta}
                            disabled={this.state.submitting || !canEdit}
                            allowClear
                            autoFocus={false}
                            isTouchDevice={this.isTouchDevice}
                          />
                          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                        </InputContainer>
                      );
                    })}
                </Fragment>
              ))}
        </FormContainer>
        {!isEmpty(data) &&
          !(this.state.submitted && isEmpty(this.state.errors)) && (
            <Footer>
              <FormFooterContainer
                onSubmit={this.handleSubmit}
                withoutMargin
                submitting={this.state.submitting}
                submitText={translate('SUBMIT_CHANGES')}
                reset={
                  !isEqual(this.state, this.getInitialState(this.props)) ? this.resetForm : null
                }
              />
            </Footer>
          )}
      </Fragment>
    );
  }
}

export default injectI18n(MyContactForm);

//
// Utils
//

const FormContainer = styled.div`
  padding: 1.5rem;
`;
const FormSectionTitleContainer = styled.div`
  margin: 1rem 0;
`;
const FormSectionTitle = styled.div`
  font-weight: 600;
`;
const FormSectionSubtitle = styled.div`
  font-weight: 400;
  margin-top: 0.2rem;
  font-size: 0.8rem;
`;
const Footer = styled.div`
  width: 100%;
  border-top: 1px solid rgb(232, 232, 232);
  padding: 0.5rem;
`;
const StyledAlert = styled(IoAndroidAlert)`
  margin-left: 0.3rem;
  font-size: 1.1rem;
  color: ${props => props.theme.invalidColor};
`;
const ErrorMessage = styled.div`
  color: ${props => props.theme.invalidColor};
  font-size: 0.9rem;
`;
const RequiredInfo = styled.span`
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 300;
  color: rgb(117, 117, 117);
`;
const EmptyValue = styled.span`
  font-style: italic;
`;
const ScInitialValue = styled.div`
  ${props => props.changed && 'text-decoration: line-through'};
  opacity: 0.5;
`;
const ScNewValue = styled.div`
  font-weight: 500;
`;
