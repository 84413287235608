// @flow

import React, { Component } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import ReactTestUtils from 'react-dom/test-utils';
import { datePattern } from '@hypercharge/hyper-react-base/lib/utils';

type PropsT = {
  onChange: (value: any) => void,
  value: any,
  disabled: boolean,
  onKeyDown: (e: SyntheticEvent<*>) => void,
  autoFocus?: boolean
};

type StateT = {
  focused: boolean
};

const Wrapper = styled.div`
  .ant-calendar-picker {
    margin-bottom: 0 !important;
  }
`;

const fullWidthStyle = { width: '100%' };

const getMillis = (value): number | null => (value && value.valueOf()) || null;

class DateEditor extends Component<PropsT, StateT> {
  state = {
    focused: false
  };

  render() {
    const { value, onChange, disabled, onKeyDown, autoFocus = true } = this.props;
    return (
      <Wrapper onClick={e => e.stopPropagation()}>
        <DatePicker
          autoFocus={autoFocus}
          format={datePattern}
          value={value ? moment(value) : null}
          onChange={value => onChange(getMillis(value))}
          disabled={disabled}
          onKeyDown={onKeyDown}
          onFocus={() => this.setState({ focused: true })}
          style={fullWidthStyle}
          ref={ref => {
            if (ref && autoFocus && !this.state.focused) {
              // https://github.com/ant-design/ant-design/issues/8269
              const node: any = ReactDOM.findDOMNode(ref); // eslint-disable-line react/no-find-dom-node
              node && ReactTestUtils.Simulate.click(node.querySelector('input'));
            }
          }}
        />
      </Wrapper>
    );
  }
}

export default DateEditor;
