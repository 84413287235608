// @flow

import React from 'react';
import styled, { css } from 'styled-components';
import { BasicInput, BasicTextArea } from '@hypercharge/hyper-react-base/lib/form';

import type { TextMeta } from '../../../types.js';

const removePaddingCss = css`
  padding-bottom: 0 !important;
`;

const StyledInput = styled(BasicInput)`
  ${removePaddingCss};
`;

const StyledTextArea = styled(BasicTextArea)`
  ${removePaddingCss};
  textarea {
    overflow: auto;
  }
`;

type PropsT = {
  onChange: (value: any) => void,
  value: any,
  meta: TextMeta,
  disabled: boolean,
  onKeyDown: (e: SyntheticEvent<*>) => void,
  autoFocus?: boolean
};

const PlainTextEditor = ({
  meta,
  value,
  onChange,
  disabled,
  onKeyDown,
  autoFocus = true
}: PropsT) => {
  const valueOrEmptyString = value || '';
  return meta && meta.multiline ? (
    <StyledTextArea
      input={{ value: valueOrEmptyString }}
      autoFocus={autoFocus}
      onChange={e => onChange(e.target.value)}
      disabled={disabled}
      onKeyDown={e => {
        if (onKeyDown && e.key !== 'Enter') {
          onKeyDown(e);
        }
      }}
      rows={valueOrEmptyString.split('\n').length}
    />
  ) : (
    <StyledInput
      input={{ value: valueOrEmptyString }}
      autoFocus={autoFocus}
      onChange={e => onChange(e.target.value || null)}
      disabled={disabled}
      onKeyDown={onKeyDown}
    />
  );
};

export default PlainTextEditor;
