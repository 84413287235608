// @flow

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import memoize from 'fast-memoize';
import Frame from '../../common/components/frame';
import { GET_STARTED_BY_EMAIL_PATH, GET_STARTED_CODE_PATH, ACCOUNTS_PATH } from '../constants.js';
import { redirectIfAuthenticated } from '../authRedirectWrappers.js';
import GetStartedByEmailFormContainer from './get-started/GetStartedByEmailFormContainer.js';
import GetStartedCodeFormContainer from './get-started/GetStartedCodeFormContainer.js';
import ChooseAccountContainer from './choose-account/ChooseAccountContainer.js';

import type { ContextRouter } from 'react-router';

type PropsT = {
  redirectPath?: string
} & ContextRouter<{}>;

const getRedirectLocation = memoize((url, search, hash) => ({
  pathname: `${url}${GET_STARTED_BY_EMAIL_PATH}`,
  search,
  hash
}));

const AuthRoutes = ({ location: { search, hash }, match: { url } }: PropsT) => (
  <Frame>
    <Switch>
      <Route
        exact
        path={`${url}${GET_STARTED_BY_EMAIL_PATH}`}
        component={GetStartedByEmailFormContainer}
      />
      <Route
        exact
        path={`${url}${GET_STARTED_CODE_PATH}`}
        component={GetStartedCodeFormContainer}
      />
      <Route exact path={`${url}${ACCOUNTS_PATH}`} component={ChooseAccountContainer} />
      <Redirect to={getRedirectLocation(url, search, hash)} />
    </Switch>
  </Frame>
);

export default redirectIfAuthenticated(AuthRoutes);
