// @flow

type PropsT = {
  value: any
};

const EmailDisplay = ({ value }: PropsT) => {
  return value || '\u00a0';
};

export default EmailDisplay;
