// @flow

import dtParse from 'date-fns/parse';
import jwtDecode from 'jwt-decode';
import { createReducer } from '@hypercharge/hyper-react-base/lib/utils/reducerUtils.js';

import type {
  DisplayTenant,
  DisplayTenantRequestT,
  DisplayTenantSuccessT,
  DisplayTenantFailT,
  LogInSuccessT,
  RefreshAuthTokenSuccessT
} from './types.js';

type FetchStatusT = {
  pending: boolean,
  failed: boolean
};

export type StateT = {
  entityId: ?string,
  refreshToken: ?string,
  refreshTokenExpiry: ?Date,
  jwt: ?string,
  jwtExpiry: ?Date,
  displayTenant: ?DisplayTenant,
  displayTenantStatus: FetchStatusT
};

type JwtT = {
  exp: number,
  sub: string,
  tenantId: string
};

const initialState: StateT = {
  entityId: null,
  refreshToken: null,
  jwt: null,
  jwtExpiry: null,
  refreshTokenExpiry: null,
  displayTenant: null,
  displayTenantStatus: { pending: false, failed: false }
};

const authReducer = createReducer(initialState, {
  AUTH__LOG_IN_SUCCESS: (s: StateT, a: LogInSuccessT) => {
    const { idToken, refreshToken, refreshTokenExpiry } = a.payload;
    const decodedToken: JwtT = jwtDecode(idToken);
    s.entityId = decodedToken.sub;
    s.jwt = idToken;
    s.jwtExpiry = dtParse(decodedToken.exp * 1000);
    s.refreshToken = refreshToken;
    s.refreshTokenExpiry = dtParse(refreshTokenExpiry);
  },
  AUTH__REFRESH_AUTH_TOKEN_SUCCESS: (s: StateT, a: RefreshAuthTokenSuccessT) => {
    const decodedToken: JwtT = jwtDecode(a.payload.idToken);
    s.entityId = decodedToken.sub;
    s.jwt = a.payload.idToken;
    s.jwtExpiry = dtParse(decodedToken.exp * 1000);
  },
  AUTH__FETCH_DISPLAY_TENANT: (s: StateT, a: DisplayTenantRequestT) => {
    s.displayTenantStatus.pending = true;
    s.displayTenantStatus.failed = false;
    s.displayTenant = null;
  },
  AUTH__FETCH_DISPLAY_TENANT_SUCCESS: (s: StateT, a: DisplayTenantSuccessT) => {
    s.displayTenant = a.payload;
    s.displayTenantStatus.pending = false;
  },
  AUTH__FETCH_DISPLAY_TENANT_FAIL: (s: StateT, a: DisplayTenantFailT) => {
    s.displayTenantStatus.pending = false;
    s.displayTenantStatus.failed = true;
  }
});

export default authReducer;
