// @flow

import type { FetchItemsByIdT, SearchItemsT, FilterRequestT } from './types.js';

// ////////////////////
// Item (just values)
// ////////////////////

const SEARCH_ITEMS_PATH = '/v2-gdpr/search';

export const fetchItemsById = (
  definitionId: string,
  ids: string[],
  language: string
): FetchItemsByIdT => ({
  type: 'CMS__FETCH_ITEMS_BY_ID',
  payload: {
    definitionId,
    query: {
      sortBy: [
        {
          field: 'entityId',
          order: 'DESC'
        }
      ],
      query: {
        condition: 'OR',
        filters: ids.map(id => ({ field: 'entityId', operator: 'IS', data: id }))
      },
      limit: ids.length,
      offset: 0,
      languageCode: language
    }
  },
  meta: {
    http: {
      path: SEARCH_ITEMS_PATH,
      method: 'POST',
      actionContext: { ids }
    }
  }
});

export const searchItems = (definitionId: string, query: FilterRequestT): SearchItemsT => ({
  type: 'CMS__SEARCH_ITEMS',
  payload: {
    definitionId,
    query
  },
  meta: {
    http: {
      path: SEARCH_ITEMS_PATH,
      method: 'POST'
    }
  }
});
