// @flow

type ThemeT = { [prop: string]: string };

export type WithThemeT = { theme: ThemeT };

export const theme: ThemeT = {
  textColor: '#5a5a5a',
  textColorLight: '#8c8c8c',
  mainColor: '#4da0ff',
  mainColorActive: 'rgba(77, 160, 255, 0.86)',
  mainColorVibrant: '#2189ff',
  mainColorSoft: '#f4f8ff',
  alternativeColor: '#4da0ff',
  alternativeColorActive: 'rgba(77, 160, 255, 0.86)',
  invalidColor: '#ff9b20',
  thinBorder: '1px solid #dcdcdc',
  cardBorderRadius: '0.25rem',
  buttonBorderRadius: '4px',
  defaultRadius: '2px',
  cardBoxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
  fontFamily:
    "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, 'Helvetica Neue', sans-serif"
};
