// @flow

import en from './en.js';
import nl from './nl.js';
import fr from './fr.js';

import type { MessagesByLanguageT } from '@hypercharge/hyper-react-base/lib/i18n';

const authMessages: MessagesByLanguageT = { en, nl, fr };

export default authMessages;
