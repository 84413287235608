// @flow

import { get } from 'lodash';
import { getDataState } from '../common/reducers/selectors.js';

import type { GlobalStateT } from '../../common/reducers/reducers.js';
import type { Item } from './types.js';
import type { ItemsStateT } from './reducer.js';

// ////////////////////
// Item (just values)
// ////////////////////

const getItemsState = (s: GlobalStateT): ItemsStateT => getDataState(s).items;

export const getItem = (s: GlobalStateT, entityId: string): ?Item =>
  get(getItemsState(s), ['byId', entityId]);

export const isItemAvailable = (s: GlobalStateT, entityId: string): boolean => {
  const status = get(getItemsState(s), ['status', entityId]);
  return !!getItem(s, entityId) || (status != null && (status.lastFetch != null || status.pending));
};

export const isItemPending = (s: GlobalStateT, entityId: string): boolean =>
  get(getItemsState(s), ['status', entityId, 'pending'], false);

export const isItemFailed = (s: GlobalStateT, entityId: string): boolean =>
  get(getItemsState(s), ['status', entityId, 'failed'], false);
