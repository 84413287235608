// @flow

import { getMeState } from '../common/reducers/rootSelector.js';

import type { FetchStatusT } from '@hypercharge/hyper-react-base/lib/types';
import type { GlobalStateT } from '../common/reducers/reducers.js';
import type { DisplayDataList } from '../cms';
import type { DisplayGdprConfig } from '@hypercharge/portal-utils';

export const getDisplayGdprConfig = (s: GlobalStateT): ?DisplayGdprConfig =>
  getMeState(s).displayGdprConfig;

export const getDisplayGdprConfigStatus = (s: GlobalStateT): FetchStatusT => {
  const meState = getMeState(s);
  return {
    isAvailable: !!meState.displayGdprConfig,
    isPending: meState.displayGdprConfigStatus.pending,
    isFailed: meState.displayGdprConfigStatus.failed
  };
};

export const getMe = (s: GlobalStateT): ?DisplayDataList => getMeState(s).me;

export const getMeStatus = (s: GlobalStateT): FetchStatusT => {
  const meState = getMeState(s);
  return {
    isAvailable: !!meState.me,
    isPending: meState.meStatus.pending,
    isFailed: meState.meStatus.failed
  };
};
