// @flow

const authEnhanceRootReducer = (rootReducer: any, persistConfig: any) => (
  state: any,
  action: any
) => {
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  if (['AUTH__LOG_OUT', 'AUTH__REFRESH_AUTH_TOKEN_FAIL'].includes(action.type)) {
    persistConfig.storage.removeItem(`persist:${persistConfig.key}`);
    state = undefined;

    // this is a temporary hack until we find a better solution
    location.reload(); // TODO: evaluate if still needed
  }
  return rootReducer(state, action);
};

export default authEnhanceRootReducer;
