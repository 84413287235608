// @flow

const messages = {
  YES: 'Yes',
  NO: 'No',
  REQUIRED: 'Required',
  SUBMIT_CHANGES: 'Submit changes',
  PROPERTY_FIELD_TYPE_link_TEXT_PLACEHOLDER: 'Text to display',
  PROPERTY_FIELD_TYPE_link_LINK_PLACEHOLDER: 'Paste or type link here',
  VALIDATIONS__INVALID_PHONE_NUMBER: 'Invalid phone number',
  EMPTY_VALUE: 'Empty'
};

export default messages;
