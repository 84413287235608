// @flow

import type {
  DisplayTenantRequestT,
  AuthCodeRequestByEmailT,
  RetrieveFactorAccountsRequestT,
  LogInRequestT,
  LogOutT,
  RefreshAuthTokenRequestT,
  ForceRefreshAuthTokenT
} from './types.js';

const authPath = '/v2-auth';

export const fetchDisplayTenant = (tenantId: string): DisplayTenantRequestT => ({
  type: 'AUTH__FETCH_DISPLAY_TENANT',
  meta: {
    http: {
      path: `/v2-core/tenants/${tenantId}/display`,
      method: 'GET'
    },
    auth: false
  }
});

export const requestAuthCodeByEmail = (
  tenantId: string,
  email: string,
  language: string
): AuthCodeRequestByEmailT => ({
  type: 'AUTH__REQUEST_AUTH_CODE_BY_EMAIL',
  payload: {
    tenantId,
    email
    // language // TODO: validate with Ivo
  },
  meta: {
    http: {
      path: `${authPath}/email/request`,
      method: 'POST',
      allowPromiseReject: true
    },
    auth: false
  }
});

export const retrieveFactorAccounts = (
  tenantId: string,
  email: string,
  code: string
): RetrieveFactorAccountsRequestT => ({
  type: 'AUTH__RETRIEVE_FACTOR_ACCOUNTS',
  payload: {
    tenantId,
    email,
    code
  },
  meta: {
    http: {
      path: `${authPath}/email/accounts`,
      method: 'POST',
      allowPromiseReject: true
    },
    auth: false
  }
});

export const logIn = (
  tenantId: string,
  entityId: string,
  email: string,
  code: string
): LogInRequestT => ({
  type: 'AUTH__LOG_IN',
  payload: {
    tenantId,
    entityId,
    email,
    code
  },
  meta: {
    http: {
      path: `${authPath}/email/login`,
      method: 'POST',
      allowPromiseReject: true
    },
    auth: false
  }
});

export const signOut = (
  tenantId: string,
  entityId: string,
  refreshTokenExpiry: ?Date
): LogOutT => ({
  type: 'AUTH__LOG_OUT',
  payload: {
    tenantId,
    entityId,
    refreshTokenExpiry
  },
  meta: {
    http: {
      path: `${authPath}/logout`,
      method: 'POST',
      allowPromiseReject: true
    },
    auth: false
  }
});

export const requestAuthToken = (
  tenantId: string,
  entityId: string,
  refreshToken: ?string
): RefreshAuthTokenRequestT => ({
  type: 'AUTH__REFRESH_AUTH_TOKEN',
  payload: {
    tenantId,
    entityId,
    refreshToken
  },
  meta: {
    http: {
      path: `${authPath}/refresh`,
      method: 'POST'
    },
    auth: false
  }
});

export const refreshAuthToken = (): ForceRefreshAuthTokenT => ({
  type: 'AUTH__FORCE_REFRESH_AUTH_TOKEN'
});
