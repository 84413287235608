// @flow

import React from 'react';
import styled from 'styled-components';
import { compact } from 'lodash';
import MdMailOutline from 'react-icons/lib/md/mail-outline';
import MdPhone from 'react-icons/lib/md/phone';
import ArrowSection from '@hypercharge/hyper-react-base/lib/common/arrow-section';
import { Subtitle } from '../../../common/components/frame';

import type { FactorAccountT } from '../../types.js';

type PropsT = {
  className?: string,
  account: FactorAccountT,
  pending: boolean,
  disabled: boolean
};

const StyledArrowSection = styled(ArrowSection)`
  background: #fff;
  padding: 1rem;
  border: 1px solid #d9e3ed;
  border-top: 0;
`;
const Name = styled(Subtitle)`
  font-size: 1.2rem;
  font-weight: 600;
`;
const ContactInfo = styled(Subtitle)`
  font-weight: 400;
  color: #a5a5a5;
`;
const ContactIcon = styled.div`
  margin-right: 0.5rem;
`;
const EmailIcon = ContactIcon.withComponent(MdMailOutline);
const PhoneIcon = ContactIcon.withComponent(MdPhone);

const AccountRow = ({
  className,
  account: { firstName, lastName, phone, email },
  ...otherProps
}: PropsT) => (
  <StyledArrowSection className={className} {...otherProps}>
    <Name>{compact([firstName, lastName]).join(' ')}</Name>
    {email && (
      <ContactInfo className="cp-c-row cp-c-align-start-center">
        <EmailIcon /> {email}
      </ContactInfo>
    )}
    {phone && (
      <ContactInfo className="cp-c-row cp-c-align-start-center">
        <PhoneIcon /> {phone}
      </ContactInfo>
    )}
  </StyledArrowSection>
);

export default AccountRow;
