// @flow

import { formatDate, formatDateTime } from '@hypercharge/hyper-react-base/lib/utils';

import type { DateMeta } from '../../../types.js';

type PropsT = {
  value: ?number,
  meta: DateMeta
};

const DateDisplay = ({ value, meta }: PropsT) =>
  (value && (meta.withTime ? formatDateTime(value) : formatDate(value))) || '\u00a0';

export default DateDisplay;
