// @flow

import { combineReducers } from 'redux';
import data from '../../data/reducer.js';

import type { StateT as DataStateT } from '../../data/reducer.js';

export type StateT = { data: DataStateT };

export default combineReducers({ data });
